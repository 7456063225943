import { Button, Container, FormGroup, Paper, Typography } from "@mui/material"
import { useState, useEffect } from "react"
import { useDispatch } from "react-redux"
import { showError } from "../../features/errors/Errors"
import { setIsLoading } from "../../features/menus/AppMenu"
import { useErsysDataApi } from "../ErsysDataApiProvider"
import { RotateLeftSharp } from "@mui/icons-material"
import AdministrativeToolsWaitOnProcessModal from "./AdministrativeToolsWaitOnProcessModal"
import { MinimalStore } from "../../apis/ersys-public"
import { useFirebase } from "../FirebaseProvider"
import { RollupRequest } from "../../apis/ersys-data"
import dayjs from "dayjs"
import { useForm, useFormState } from "react-hook-form"
import { FormInputDropdown, FormInputDropdownOption } from "../forms/FormInputDropdown"
import FormInputDatePicker from "../forms/FormInputDatePicker"

const formDefaults: RollupRequest = {
    endDate: dayjs().format(),
    startDate: dayjs().format(),
    storeId: 0
}

const AdministrativeToolsRollupAccountsTab: React.FC = () => {
    const ersysDataApiServices = useErsysDataApi()
    const [loading, setLoading] = useState<boolean>(false)
    const [showWaitingModal, setShowWaitingModal] = useState<boolean>(false)
    const [stores, setStores] = useState<MinimalStore[]>([])
    const [id, setId] = useState<string>("")
    const [formSpacing] = useState(2)
    const { handleSubmit, control, } =
        useForm<RollupRequest>({
            defaultValues: formDefaults,
            reValidateMode: "onSubmit",
        })
    const { isDirty } = useFormState({ control })
    const dispatch = useDispatch()
    const { isReady } = useFirebase()

    useEffect(() => {
        dispatch(setIsLoading(loading))
    }, [loading, dispatch])

    useEffect(() => {
        if (!isReady) {
            return
        }
        const fetch = async () => {
            try {
                var storesResp = await ersysDataApiServices.storesService.storesList()
                setStores(storesResp.data.stores)
            }
            catch (ex: any) {
                dispatch(
                    showError({
                        title: "Unexpected Error occurred",
                        message:
                            "An unexpected error occurred while getting Store data. If problem persists please contact IT.",
                    }),
                )
                console.log(ex)
            }
        }
        fetch()
    }, [dispatch, setStores, ersysDataApiServices.storesService, isReady])



    
    const storeOptions = (): FormInputDropdownOption[] => {
        if(!stores) {
            return []
        }
        return [
            {
                label: "Select Store",
                value: 0
            },
            ...stores.map(s => {
                return {
                    label: s.fullName,
                    value: s.id
                }
            })
        ]
    }

    const rollup = async (req: RollupRequest) => {
        setLoading(true)
        try {
            const response = await ersysDataApiServices.bulkService.bulkRollupAccounts(req)
            setId(response.data.id)
            setShowWaitingModal(true)
        }
        catch (ex: any) {
            dispatch(
                showError({
                    title: "Unexpected Error occurred",
                    message:
                        "An unexpected error occurred while rolling up accounts. If problem persists please contact IT.",
                }),
            )
            console.log(ex)
        } finally {
            setLoading(false)
        }
    }

    return (
        <Container>
            <AdministrativeToolsWaitOnProcessModal 
            id={id}  
            onClose={() => setShowWaitingModal(false)} 
            open={showWaitingModal} 
            title="Rolling up accounts"
            noCounts
            />
            <Paper sx={{ padding: 2, margin: 2 }}>
                <Typography>This action is irrevsersible. Please be careful.</Typography>
                <FormGroup>
                        <FormInputDropdown
                            spacing={formSpacing}
                            name={"storeId"}
                            control={control}
                            label={"Store"}
                            options={storeOptions()}
                            rules={{ required: true, min: 1}}
                        />
                        <FormInputDatePicker 
                            name="startDate"
                            control={control} 
                            label="Start Date" 
                            spacing={formSpacing}                    
                        />
                        <FormInputDatePicker 
                            name="endDate"
                            control={control} 
                            label="End Date" 
                            spacing={formSpacing}                    
                        />
                </FormGroup>
                <div className="edit-user-save-btn">
                    <Button
                        variant="contained"
                        disabled={!isDirty}
                        onClick={handleSubmit(rollup)}
                        endIcon={<RotateLeftSharp />}
                    >
                        Rollup Accounts
                    </Button>
                </div>
            </Paper>            
        </Container>
    )
}

export default AdministrativeToolsRollupAccountsTab