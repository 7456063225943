/* tslint:disable */
/* eslint-disable */
/**
 * ErSys Backend Admin
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Account
 */
export interface Account {
    /**
     * 
     * @type {AccountType}
     * @memberof Account
     */
    'accountType': AccountType;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'billingAddressLineOne': string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'billingAddressLineTwo': string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'billingCity': string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'billingCountry': string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'billingEmail': string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'billingFirstName': string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'billingLastName': string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'billingPhone': string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'billingPostal': string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'billingState': string;
    /**
     * 
     * @type {ClassYear}
     * @memberof Account
     */
    'classYear': ClassYear;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'comments'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'createdDate': string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'custom1'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Account
     */
    'disableAutoChargePiecePrice': boolean;
    /**
     * 
     * @type {number}
     * @memberof Account
     */
    'discount': number;
    /**
     * 
     * @type {number}
     * @memberof Account
     */
    'graduationYear'?: number;
    /**
     * 
     * @type {number}
     * @memberof Account
     */
    'id': number;
    /**
     * 
     * @type {boolean}
     * @memberof Account
     */
    'isActive': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Account
     */
    'isFlaggedForQuality': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Account
     */
    'isFlaggedNoMarking': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Account
     */
    'isHung': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Account
     */
    'isSuspended': boolean;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'lastChanged'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Account
     */
    'locationId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Account
     */
    'previousBalance'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Account
     */
    'previousBalance30'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Account
     */
    'previousBalance60'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Account
     */
    'previousBalance90'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Account
     */
    'previousBalanceSub30'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'primaryEmail': string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'primaryFirstName': string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'primaryLastName': string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'primaryPhone': string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'room'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Account
     */
    'storeId': number;
}
/**
 * 
 * @export
 * @interface AccountBag
 */
export interface AccountBag {
    /**
     * 
     * @type {string}
     * @memberof AccountBag
     */
    'bagNumber': string;
    /**
     * 
     * @type {number}
     * @memberof AccountBag
     */
    'id': number;
    /**
     * 
     * @type {boolean}
     * @memberof AccountBag
     */
    'isActive': boolean;
}
/**
 * 
 * @export
 * @interface AccountBagSet
 */
export interface AccountBagSet {
    /**
     * 
     * @type {Array<AccountBag>}
     * @memberof AccountBagSet
     */
    'bags': Array<AccountBag>;
}
/**
 * 
 * @export
 * @interface AccountBagToggleRequest
 */
export interface AccountBagToggleRequest {
    /**
     * 
     * @type {boolean}
     * @memberof AccountBagToggleRequest
     */
    'isActive'?: boolean;
}
/**
 * 
 * @export
 * @interface AccountOrder
 */
export interface AccountOrder {
    /**
     * 
     * @type {string}
     * @memberof AccountOrder
     */
    'cancelledOn'?: string;
    /**
     * 
     * @type {Array<AccountOrderDiscounts>}
     * @memberof AccountOrder
     */
    'discounts': Array<AccountOrderDiscounts>;
    /**
     * 
     * @type {number}
     * @memberof AccountOrder
     */
    'id': number;
    /**
     * 
     * @type {boolean}
     * @memberof AccountOrder
     */
    'isCancelled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AccountOrder
     */
    'orderDate': string;
    /**
     * 
     * @type {string}
     * @memberof AccountOrder
     */
    'paymentPlanName': string;
    /**
     * 
     * @type {Array<AccountOrderPlan>}
     * @memberof AccountOrder
     */
    'plans': Array<AccountOrderPlan>;
    /**
     * 
     * @type {Array<AccountOrderRemainingPayment>}
     * @memberof AccountOrder
     */
    'remainingPayments': Array<AccountOrderRemainingPayment>;
    /**
     * 
     * @type {number}
     * @memberof AccountOrder
     */
    'total': number;
}
/**
 * 
 * @export
 * @interface AccountOrderDiscounts
 */
export interface AccountOrderDiscounts {
    /**
     * 
     * @type {number}
     * @memberof AccountOrderDiscounts
     */
    'amount': number;
    /**
     * 
     * @type {string}
     * @memberof AccountOrderDiscounts
     */
    'notes': string;
}
/**
 * 
 * @export
 * @interface AccountOrderPlan
 */
export interface AccountOrderPlan {
    /**
     * 
     * @type {boolean}
     * @memberof AccountOrderPlan
     */
    'isAddon'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AccountOrderPlan
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof AccountOrderPlan
     */
    'value': number;
}
/**
 * 
 * @export
 * @interface AccountOrderRemainingPayment
 */
export interface AccountOrderRemainingPayment {
    /**
     * 
     * @type {string}
     * @memberof AccountOrderRemainingPayment
     */
    'payDate': string;
    /**
     * 
     * @type {number}
     * @memberof AccountOrderRemainingPayment
     */
    'value': number;
}
/**
 * 
 * @export
 * @interface AccountOrderSet
 */
export interface AccountOrderSet {
    /**
     * 
     * @type {Array<AccountOrder>}
     * @memberof AccountOrderSet
     */
    'orders': Array<AccountOrder>;
}
/**
 * 
 * @export
 * @interface AccountPlan
 */
export interface AccountPlan {
    /**
     * 
     * @type {number}
     * @memberof AccountPlan
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof AccountPlan
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface AccountPlanEnableRequest
 */
export interface AccountPlanEnableRequest {
    /**
     * 
     * @type {number}
     * @memberof AccountPlanEnableRequest
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface AccountPlanSet
 */
export interface AccountPlanSet {
    /**
     * 
     * @type {Array<AccountPlan>}
     * @memberof AccountPlanSet
     */
    'plans': Array<AccountPlan>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const AccountType = {
    Student: 'student',
    Commercial: 'commercial',
    Faculty: 'faculty',
    LinenRental: 'linen_rental',
    Other: 'other'
} as const;

export type AccountType = typeof AccountType[keyof typeof AccountType];


/**
 * 
 * @export
 * @interface AccountUser
 */
export interface AccountUser {
    /**
     * 
     * @type {string}
     * @memberof AccountUser
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface AccountUserSet
 */
export interface AccountUserSet {
    /**
     * 
     * @type {Array<AccountUser>}
     * @memberof AccountUserSet
     */
    'users': Array<AccountUser>;
}
/**
 * 
 * @export
 * @interface BulkAccountCreationResponse
 */
export interface BulkAccountCreationResponse {
    /**
     * 
     * @type {number}
     * @memberof BulkAccountCreationResponse
     */
    'end': number;
    /**
     * 
     * @type {number}
     * @memberof BulkAccountCreationResponse
     */
    'start': number;
}
/**
 * 
 * @export
 * @interface CalcAndSyncRequest
 */
export interface CalcAndSyncRequest {
    /**
     * 
     * @type {string}
     * @memberof CalcAndSyncRequest
     */
    'cutoffDate': string;
}
/**
 * 
 * @export
 * @interface CardOnFile
 */
export interface CardOnFile {
    /**
     * 
     * @type {string}
     * @memberof CardOnFile
     */
    'brand': string;
    /**
     * 
     * @type {number}
     * @memberof CardOnFile
     */
    'expirationMonth': number;
    /**
     * 
     * @type {number}
     * @memberof CardOnFile
     */
    'expirationYear': number;
    /**
     * 
     * @type {number}
     * @memberof CardOnFile
     */
    'lastFour': number;
}
/**
 * 
 * @export
 * @interface ChecklistEntry
 */
export interface ChecklistEntry {
    /**
     * 
     * @type {string}
     * @memberof ChecklistEntry
     */
    'createdDate'?: string | null;
    /**
     * 
     * @type {Array<ChecklistEntryDetail>}
     * @memberof ChecklistEntry
     */
    'details': Array<ChecklistEntryDetail>;
    /**
     * 
     * @type {number}
     * @memberof ChecklistEntry
     */
    'id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ChecklistEntry
     */
    'specialInstructions'?: string | null;
}
/**
 * 
 * @export
 * @interface ChecklistEntryDetail
 */
export interface ChecklistEntryDetail {
    /**
     * 
     * @type {number}
     * @memberof ChecklistEntryDetail
     */
    'id'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof ChecklistEntryDetail
     */
    'isWdf'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof ChecklistEntryDetail
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof ChecklistEntryDetail
     */
    'value': number;
}
/**
 * 
 * @export
 * @interface ChecklistSet
 */
export interface ChecklistSet {
    /**
     * 
     * @type {Array<ChecklistEntry>}
     * @memberof ChecklistSet
     */
    'checklists': Array<ChecklistEntry>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ClassYear = {
    Freshman: 'freshman',
    Sophomore: 'sophomore',
    Junior: 'junior',
    Senior: 'senior'
} as const;

export type ClassYear = typeof ClassYear[keyof typeof ClassYear];


/**
 * 
 * @export
 * @interface ClientError
 */
export interface ClientError {
    /**
     * 
     * @type {string}
     * @memberof ClientError
     */
    'additionalData'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientError
     */
    'detail': string;
    /**
     * 
     * @type {string}
     * @memberof ClientError
     */
    'instance': string;
    /**
     * 
     * @type {number}
     * @memberof ClientError
     */
    'status': number;
    /**
     * 
     * @type {string}
     * @memberof ClientError
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof ClientError
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface CreateAccountRequest
 */
export interface CreateAccountRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateAccountRequest
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountRequest
     */
    'first': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountRequest
     */
    'last': string;
    /**
     * 
     * @type {number}
     * @memberof CreateAccountRequest
     */
    'store': number;
    /**
     * 
     * @type {AccountType}
     * @memberof CreateAccountRequest
     */
    'type': AccountType;
}
/**
 * 
 * @export
 * @interface CreateAccountUserRequest
 */
export interface CreateAccountUserRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateAccountUserRequest
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface CreateCardOnFileRequest
 */
export interface CreateCardOnFileRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateCardOnFileRequest
     */
    'brand': string;
    /**
     * 
     * @type {number}
     * @memberof CreateCardOnFileRequest
     */
    'expirationMonth': number;
    /**
     * 
     * @type {number}
     * @memberof CreateCardOnFileRequest
     */
    'expirationYear': number;
    /**
     * 
     * @type {number}
     * @memberof CreateCardOnFileRequest
     */
    'lastFour': number;
    /**
     * 
     * @type {string}
     * @memberof CreateCardOnFileRequest
     */
    'token': string;
}
/**
 * 
 * @export
 * @interface CreateLocationRequest
 */
export interface CreateLocationRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateLocationRequest
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface CreatePlanRequest
 */
export interface CreatePlanRequest {
    /**
     * 
     * @type {number}
     * @memberof CreatePlanRequest
     */
    'globalPlanId': number;
}
/**
 * 
 * @export
 * @interface CreateStoreRequest
 */
export interface CreateStoreRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateStoreRequest
     */
    'fullName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateStoreRequest
     */
    'prefix': string;
    /**
     * 
     * @type {string}
     * @memberof CreateStoreRequest
     */
    'shortName': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const CustomTypes = {
    String: 'string',
    Date: 'date',
    Number: 'number'
} as const;

export type CustomTypes = typeof CustomTypes[keyof typeof CustomTypes];


/**
 * 
 * @export
 * @interface Department
 */
export interface Department {
    /**
     * 
     * @type {Array<number>}
     * @memberof Department
     */
    'ics': Array<number>;
    /**
     * 
     * @type {number}
     * @memberof Department
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Department
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface DepartmentCreateRequest
 */
export interface DepartmentCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof DepartmentCreateRequest
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface DepartmentICSetRequest
 */
export interface DepartmentICSetRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof DepartmentICSetRequest
     */
    'ics': Array<number>;
}
/**
 * 
 * @export
 * @interface Discount
 */
export interface Discount {
    /**
     * 
     * @type {string}
     * @memberof Discount
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof Discount
     */
    'description': string;
    /**
     * 
     * @type {boolean}
     * @memberof Discount
     */
    'isEnabled': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Discount
     */
    'isPercentOfTotalOrder': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Discount
     */
    'isPercentage': boolean;
    /**
     * 
     * @type {number}
     * @memberof Discount
     */
    'restrictedToPlan'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Discount
     */
    'restrictedToStore'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Discount
     */
    'value': number;
}
/**
 * 
 * @export
 * @interface DiscountCreationRequest
 */
export interface DiscountCreationRequest {
    /**
     * 
     * @type {string}
     * @memberof DiscountCreationRequest
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof DiscountCreationRequest
     */
    'description': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const DispatchSortOrder = {
    Route: 'route',
    Last: 'last',
    RtStop: 'rt_stop'
} as const;

export type DispatchSortOrder = typeof DispatchSortOrder[keyof typeof DispatchSortOrder];


/**
 * 
 * @export
 * @interface GlobalPlan
 */
export interface GlobalPlan {
    /**
     * 
     * @type {string}
     * @memberof GlobalPlan
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof GlobalPlan
     */
    'expiration': string;
    /**
     * 
     * @type {number}
     * @memberof GlobalPlan
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof GlobalPlan
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof GlobalPlan
     */
    'productionName': string;
    /**
     * 
     * @type {number}
     * @memberof GlobalPlan
     */
    'tier': number;
}
/**
 * 
 * @export
 * @interface GlobalPlanCreationRequest
 */
export interface GlobalPlanCreationRequest {
    /**
     * 
     * @type {string}
     * @memberof GlobalPlanCreationRequest
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface InventoryCategory
 */
export interface InventoryCategory {
    /**
     * 
     * @type {number}
     * @memberof InventoryCategory
     */
    'failureToReturnCost': number;
    /**
     * 
     * @type {number}
     * @memberof InventoryCategory
     */
    'id': number;
    /**
     * 
     * @type {boolean}
     * @memberof InventoryCategory
     */
    'isRental': boolean;
    /**
     * 
     * @type {string}
     * @memberof InventoryCategory
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface InventoryCategoryCreationRequest
 */
export interface InventoryCategoryCreationRequest {
    /**
     * 
     * @type {string}
     * @memberof InventoryCategoryCreationRequest
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface Link
 */
export interface Link {
    /**
     * 
     * @type {number}
     * @memberof Link
     */
    'deptId': number;
    /**
     * 
     * @type {number}
     * @memberof Link
     */
    'icId': number;
    /**
     * 
     * @type {string}
     * @memberof Link
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface LinkSet
 */
export interface LinkSet {
    /**
     * 
     * @type {Array<Link>}
     * @memberof LinkSet
     */
    'links': Array<Link>;
}
/**
 * 
 * @export
 * @interface LinkSetRequest
 */
export interface LinkSetRequest {
    /**
     * 
     * @type {Array<Link>}
     * @memberof LinkSetRequest
     */
    'ics': Array<Link>;
}
/**
 * 
 * @export
 * @interface Location
 */
export interface Location {
    /**
     * 
     * @type {number}
     * @memberof Location
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof Location
     */
    'routeStop': number;
}
/**
 * 
 * @export
 * @interface LocationSet
 */
export interface LocationSet {
    /**
     * 
     * @type {Array<Location>}
     * @memberof LocationSet
     */
    'locations': Array<Location>;
}
/**
 * 
 * @export
 * @interface LongRunningProcessStatus
 */
export interface LongRunningProcessStatus {
    /**
     * 
     * @type {number}
     * @memberof LongRunningProcessStatus
     */
    'completedCount': number;
    /**
     * 
     * @type {string}
     * @memberof LongRunningProcessStatus
     */
    'errorMessage': string;
    /**
     * 
     * @type {number}
     * @memberof LongRunningProcessStatus
     */
    'failedCount': number;
    /**
     * 
     * @type {Array<string>}
     * @memberof LongRunningProcessStatus
     */
    'failedDetails': Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof LongRunningProcessStatus
     */
    'isComplete': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LongRunningProcessStatus
     */
    'isError': boolean;
    /**
     * 
     * @type {number}
     * @memberof LongRunningProcessStatus
     */
    'totalCount': number;
}
/**
 * 
 * @export
 * @interface LongRunningTaskIdResponse
 */
export interface LongRunningTaskIdResponse {
    /**
     * 
     * @type {string}
     * @memberof LongRunningTaskIdResponse
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface MergeAccountsRequest
 */
export interface MergeAccountsRequest {
    /**
     * 
     * @type {number}
     * @memberof MergeAccountsRequest
     */
    'newAccount': number;
    /**
     * 
     * @type {number}
     * @memberof MergeAccountsRequest
     */
    'oldAccount': number;
}
/**
 * 
 * @export
 * @interface MinimalAccount
 */
export interface MinimalAccount {
    /**
     * 
     * @type {string}
     * @memberof MinimalAccount
     */
    'first': string;
    /**
     * 
     * @type {number}
     * @memberof MinimalAccount
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof MinimalAccount
     */
    'last': string;
    /**
     * 
     * @type {number}
     * @memberof MinimalAccount
     */
    'storeId': number;
}
/**
 * 
 * @export
 * @interface MinimalAccountSet
 */
export interface MinimalAccountSet {
    /**
     * 
     * @type {Array<MinimalAccount>}
     * @memberof MinimalAccountSet
     */
    'accounts': Array<MinimalAccount>;
}
/**
 * 
 * @export
 * @interface MinimalDepartment
 */
export interface MinimalDepartment {
    /**
     * 
     * @type {number}
     * @memberof MinimalDepartment
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof MinimalDepartment
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface MinimalDepartmentSet
 */
export interface MinimalDepartmentSet {
    /**
     * 
     * @type {Array<MinimalDepartment>}
     * @memberof MinimalDepartmentSet
     */
    'departments': Array<MinimalDepartment>;
}
/**
 * 
 * @export
 * @interface MinimalDiscount
 */
export interface MinimalDiscount {
    /**
     * 
     * @type {string}
     * @memberof MinimalDiscount
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof MinimalDiscount
     */
    'description': string;
    /**
     * 
     * @type {number}
     * @memberof MinimalDiscount
     */
    'restrictedToPlan'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MinimalDiscount
     */
    'restrictedToStore'?: number | null;
}
/**
 * 
 * @export
 * @interface MinimalDiscountSet
 */
export interface MinimalDiscountSet {
    /**
     * 
     * @type {Array<MinimalDiscount>}
     * @memberof MinimalDiscountSet
     */
    'discounts': Array<MinimalDiscount>;
}
/**
 * 
 * @export
 * @interface MinimalGlobalPlan
 */
export interface MinimalGlobalPlan {
    /**
     * 
     * @type {number}
     * @memberof MinimalGlobalPlan
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof MinimalGlobalPlan
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface MinimalGlobalPlanSet
 */
export interface MinimalGlobalPlanSet {
    /**
     * 
     * @type {Array<MinimalGlobalPlan>}
     * @memberof MinimalGlobalPlanSet
     */
    'plans': Array<MinimalGlobalPlan>;
}
/**
 * 
 * @export
 * @interface MinimalInventoryCategory
 */
export interface MinimalInventoryCategory {
    /**
     * 
     * @type {number}
     * @memberof MinimalInventoryCategory
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof MinimalInventoryCategory
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface MinimalInventoryCategorySet
 */
export interface MinimalInventoryCategorySet {
    /**
     * 
     * @type {Array<MinimalInventoryCategory>}
     * @memberof MinimalInventoryCategorySet
     */
    'items': Array<MinimalInventoryCategory>;
}
/**
 * 
 * @export
 * @interface MinimalPaymentPlan
 */
export interface MinimalPaymentPlan {
    /**
     * 
     * @type {number}
     * @memberof MinimalPaymentPlan
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof MinimalPaymentPlan
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface MinimalPaymentPlanSet
 */
export interface MinimalPaymentPlanSet {
    /**
     * 
     * @type {Array<MinimalPaymentPlan>}
     * @memberof MinimalPaymentPlanSet
     */
    'plans': Array<MinimalPaymentPlan>;
}
/**
 * 
 * @export
 * @interface MinimalStore
 */
export interface MinimalStore {
    /**
     * 
     * @type {string}
     * @memberof MinimalStore
     */
    'fullName': string;
    /**
     * 
     * @type {number}
     * @memberof MinimalStore
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof MinimalStore
     */
    'prefix': string;
}
/**
 * 
 * @export
 * @interface MinimalStorePlan
 */
export interface MinimalStorePlan {
    /**
     * 
     * @type {number}
     * @memberof MinimalStorePlan
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof MinimalStorePlan
     */
    'publicName': string;
    /**
     * 
     * @type {string}
     * @memberof MinimalStorePlan
     */
    'referenceName': string;
}
/**
 * 
 * @export
 * @interface MinimalStorePlanSet
 */
export interface MinimalStorePlanSet {
    /**
     * 
     * @type {Array<MinimalStorePlan>}
     * @memberof MinimalStorePlanSet
     */
    'plans': Array<MinimalStorePlan>;
}
/**
 * 
 * @export
 * @interface MinimalStoreSet
 */
export interface MinimalStoreSet {
    /**
     * 
     * @type {Array<MinimalStore>}
     * @memberof MinimalStoreSet
     */
    'stores': Array<MinimalStore>;
}
/**
 * 
 * @export
 * @interface MonthlyPayment
 */
export interface MonthlyPayment {
    /**
     * 
     * @type {number}
     * @memberof MonthlyPayment
     */
    'amount': number;
    /**
     * 
     * @type {number}
     * @memberof MonthlyPayment
     */
    'orderNumber': number;
    /**
     * 
     * @type {number}
     * @memberof MonthlyPayment
     */
    'paymentDay': number;
    /**
     * 
     * @type {string}
     * @memberof MonthlyPayment
     */
    'signUpDate': string;
}
/**
 * 
 * @export
 * @interface MonthlyPaymentSet
 */
export interface MonthlyPaymentSet {
    /**
     * 
     * @type {Array<MonthlyPayment>}
     * @memberof MonthlyPaymentSet
     */
    'payments': Array<MonthlyPayment>;
}
/**
 * 
 * @export
 * @interface NewProration
 */
export interface NewProration {
    /**
     * 
     * @type {number}
     * @memberof NewProration
     */
    'cost'?: number;
    /**
     * 
     * @type {string}
     * @memberof NewProration
     */
    'date': string;
}
/**
 * 
 * @export
 * @interface NewProrationSet
 */
export interface NewProrationSet {
    /**
     * 
     * @type {Array<NewProration>}
     * @memberof NewProrationSet
     */
    'prorations': Array<NewProration>;
}
/**
 * 
 * @export
 * @interface OnAccountChargeRequest
 */
export interface OnAccountChargeRequest {
    /**
     * 
     * @type {string}
     * @memberof OnAccountChargeRequest
     */
    'periodEnd': string;
    /**
     * 
     * @type {string}
     * @memberof OnAccountChargeRequest
     */
    'periodStart': string;
}
/**
 * 
 * @export
 * @interface Order
 */
export interface Order {
    /**
     * 
     * @type {Array<OrderDiscountDetail>}
     * @memberof Order
     */
    'discounts': Array<OrderDiscountDetail>;
    /**
     * 
     * @type {Array<OrderFeeDetail>}
     * @memberof Order
     */
    'fees': Array<OrderFeeDetail>;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    'firstPayment': number;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'orderType': string;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    'paymentPlan'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'paymentType': string;
    /**
     * 
     * @type {Array<OrderPlanDetail>}
     * @memberof Order
     */
    'plans': Array<OrderPlanDetail>;
    /**
     * 
     * @type {Array<OrderSessionDetail>}
     * @memberof Order
     */
    'sessions': Array<OrderSessionDetail>;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    'total': number;
}
/**
 * 
 * @export
 * @interface OrderCancellationRequest
 */
export interface OrderCancellationRequest {
    /**
     * 
     * @type {number}
     * @memberof OrderCancellationRequest
     */
    'orderId': number;
    /**
     * 
     * @type {number}
     * @memberof OrderCancellationRequest
     */
    'refundValue'?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderCancellationRequest
     */
    'taxRefundValue'?: number;
}
/**
 * 
 * @export
 * @interface OrderCreditCard
 */
export interface OrderCreditCard {
    /**
     * 
     * @type {string}
     * @memberof OrderCreditCard
     */
    'brand': string;
    /**
     * 
     * @type {number}
     * @memberof OrderCreditCard
     */
    'expirationMonth': number;
    /**
     * 
     * @type {number}
     * @memberof OrderCreditCard
     */
    'expirationYear': number;
    /**
     * 
     * @type {number}
     * @memberof OrderCreditCard
     */
    'lastFour': number;
    /**
     * 
     * @type {string}
     * @memberof OrderCreditCard
     */
    'token': string;
}
/**
 * 
 * @export
 * @interface OrderDiscountDetail
 */
export interface OrderDiscountDetail {
    /**
     * 
     * @type {string}
     * @memberof OrderDiscountDetail
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof OrderDiscountDetail
     */
    'isPercentOfTotalOrder'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrderDiscountDetail
     */
    'isPercentage'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof OrderDiscountDetail
     */
    'notes'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrderDiscountDetail
     */
    'value': number;
}
/**
 * 
 * @export
 * @interface OrderFeeDetail
 */
export interface OrderFeeDetail {
    /**
     * 
     * @type {string}
     * @memberof OrderFeeDetail
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof OrderFeeDetail
     */
    'value': number;
}
/**
 * 
 * @export
 * @interface OrderPlan
 */
export interface OrderPlan {
    /**
     * 
     * @type {number}
     * @memberof OrderPlan
     */
    'accountId': number;
    /**
     * 
     * @type {Array<OrderPlanPriceAdjustment>}
     * @memberof OrderPlan
     */
    'credits': Array<OrderPlanPriceAdjustment>;
    /**
     * 
     * @type {Array<OrderPlanDiscountDisplay>}
     * @memberof OrderPlan
     */
    'discounts': Array<OrderPlanDiscountDisplay>;
    /**
     * 
     * @type {Array<OrderPlanPriceAdjustment>}
     * @memberof OrderPlan
     */
    'fees': Array<OrderPlanPriceAdjustment>;
    /**
     * 
     * @type {Array<OrderPlanPaymentDisplay>}
     * @memberof OrderPlan
     */
    'payments': Array<OrderPlanPaymentDisplay>;
    /**
     * 
     * @type {Array<OrderPlanPlanDisplay>}
     * @memberof OrderPlan
     */
    'plans': Array<OrderPlanPlanDisplay>;
    /**
     * 
     * @type {number}
     * @memberof OrderPlan
     */
    'storeId': number;
    /**
     * 
     * @type {number}
     * @memberof OrderPlan
     */
    'subtotal': number;
    /**
     * 
     * @type {number}
     * @memberof OrderPlan
     */
    'total': number;
}
/**
 * 
 * @export
 * @interface OrderPlanDetail
 */
export interface OrderPlanDetail {
    /**
     * 
     * @type {boolean}
     * @memberof OrderPlanDetail
     */
    'isAddon'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof OrderPlanDetail
     */
    'planId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof OrderPlanDetail
     */
    'value': number;
}
/**
 * 
 * @export
 * @interface OrderPlanDiscountDisplay
 */
export interface OrderPlanDiscountDisplay {
    /**
     * 
     * @type {string}
     * @memberof OrderPlanDiscountDisplay
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof OrderPlanDiscountDisplay
     */
    'discountCode': string;
    /**
     * 
     * @type {number}
     * @memberof OrderPlanDiscountDisplay
     */
    'value': number;
}
/**
 * 
 * @export
 * @interface OrderPlanPaymentDisplay
 */
export interface OrderPlanPaymentDisplay {
    /**
     * 
     * @type {string}
     * @memberof OrderPlanPaymentDisplay
     */
    'futurePayDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrderPlanPaymentDisplay
     */
    'value': number;
}
/**
 * 
 * @export
 * @interface OrderPlanPlanDisplay
 */
export interface OrderPlanPlanDisplay {
    /**
     * 
     * @type {number}
     * @memberof OrderPlanPlanDisplay
     */
    'globalPlanId': number;
    /**
     * 
     * @type {boolean}
     * @memberof OrderPlanPlanDisplay
     */
    'isAddon'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPlanPlanDisplay
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof OrderPlanPlanDisplay
     */
    'storePlanId': number;
    /**
     * 
     * @type {number}
     * @memberof OrderPlanPlanDisplay
     */
    'value': number;
}
/**
 * 
 * @export
 * @interface OrderPlanPriceAdjustment
 */
export interface OrderPlanPriceAdjustment {
    /**
     * 
     * @type {string}
     * @memberof OrderPlanPriceAdjustment
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof OrderPlanPriceAdjustment
     */
    'value': number;
}
/**
 * 
 * @export
 * @interface OrderPlanRequest
 */
export interface OrderPlanRequest {
    /**
     * 
     * @type {number}
     * @memberof OrderPlanRequest
     */
    'accountId': number;
    /**
     * 
     * @type {Array<OrderPlanRequestDiscountCode>}
     * @memberof OrderPlanRequest
     */
    'discountCodes': Array<OrderPlanRequestDiscountCode>;
    /**
     * 
     * @type {number}
     * @memberof OrderPlanRequest
     */
    'paymentPlanId'?: number | null;
    /**
     * 
     * @type {Array<OrderPlanRequestPlan>}
     * @memberof OrderPlanRequest
     */
    'plans': Array<OrderPlanRequestPlan>;
}
/**
 * 
 * @export
 * @interface OrderPlanRequestDiscountCode
 */
export interface OrderPlanRequestDiscountCode {
    /**
     * 
     * @type {string}
     * @memberof OrderPlanRequestDiscountCode
     */
    'code': string;
    /**
     * 
     * @type {number}
     * @memberof OrderPlanRequestDiscountCode
     */
    'value': number;
}
/**
 * 
 * @export
 * @interface OrderPlanRequestPlan
 */
export interface OrderPlanRequestPlan {
    /**
     * 
     * @type {number}
     * @memberof OrderPlanRequestPlan
     */
    'id': number;
    /**
     * 
     * @type {boolean}
     * @memberof OrderPlanRequestPlan
     */
    'isAddon'?: boolean | null;
}
/**
 * 
 * @export
 * @interface OrderRequest
 */
export interface OrderRequest {
    /**
     * 
     * @type {OrderCreditCard}
     * @memberof OrderRequest
     */
    'creditCardTokenDto': OrderCreditCard;
    /**
     * 
     * @type {boolean}
     * @memberof OrderRequest
     */
    'isRenewal'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrderRequest
     */
    'isSilent'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrderRequest
     */
    'isUpgrade'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrderRequest
     */
    'needsBag'?: boolean | null;
    /**
     * 
     * @type {OrderPlanRequest}
     * @memberof OrderRequest
     */
    'order': OrderPlanRequest;
    /**
     * 
     * @type {string}
     * @memberof OrderRequest
     */
    'orderType': string;
    /**
     * 
     * @type {string}
     * @memberof OrderRequest
     */
    'paymentMemo'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderRequest
     */
    'paymentType': string;
}
/**
 * 
 * @export
 * @interface OrderSessionDetail
 */
export interface OrderSessionDetail {
    /**
     * 
     * @type {string}
     * @memberof OrderSessionDetail
     */
    'endDate': string;
    /**
     * 
     * @type {string}
     * @memberof OrderSessionDetail
     */
    'publicName': string;
    /**
     * 
     * @type {string}
     * @memberof OrderSessionDetail
     */
    'startDate': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const OverrideType = {
    Fixed: 'fixed',
    Percentage: 'percentage'
} as const;

export type OverrideType = typeof OverrideType[keyof typeof OverrideType];


/**
 * 
 * @export
 * @interface PatchAction
 */
export interface PatchAction {
    /**
     * 
     * @type {string}
     * @memberof PatchAction
     */
    'from'?: string | null;
    /**
     * 
     * @type {PatchOperationType}
     * @memberof PatchAction
     */
    'operationType': PatchOperationType;
    /**
     * 
     * @type {string}
     * @memberof PatchAction
     */
    'to': string;
    /**
     * 
     * @type {any}
     * @memberof PatchAction
     */
    'value'?: any | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const PatchOperationType = {
    Replace: 'Replace',
    Copy: 'Copy',
    Swap: 'Swap'
} as const;

export type PatchOperationType = typeof PatchOperationType[keyof typeof PatchOperationType];


/**
 * 
 * @export
 * @interface PatchRequest
 */
export interface PatchRequest {
    /**
     * 
     * @type {Array<PatchAction>}
     * @memberof PatchRequest
     */
    'actions'?: Array<PatchAction>;
}
/**
 * 
 * @export
 * @interface PaymentPlan
 */
export interface PaymentPlan {
    /**
     * 
     * @type {number}
     * @memberof PaymentPlan
     */
    'adminFee': number;
    /**
     * 
     * @type {Array<PaymentPlanDate>}
     * @memberof PaymentPlan
     */
    'dates': Array<PaymentPlanDate>;
    /**
     * 
     * @type {number}
     * @memberof PaymentPlan
     */
    'firstPaymentPercentage': number;
    /**
     * 
     * @type {number}
     * @memberof PaymentPlan
     */
    'id': number;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentPlan
     */
    'isPublic': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentPlan
     */
    'isRenewalOnly': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentPlan
     */
    'isUpgradeOnly': boolean;
    /**
     * 
     * @type {string}
     * @memberof PaymentPlan
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PaymentPlan
     */
    'publicName': string;
    /**
     * 
     * @type {string}
     * @memberof PaymentPlan
     */
    'validUntil': string;
}
/**
 * 
 * @export
 * @interface PaymentPlanCreationRequest
 */
export interface PaymentPlanCreationRequest {
    /**
     * 
     * @type {string}
     * @memberof PaymentPlanCreationRequest
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface PaymentPlanDate
 */
export interface PaymentPlanDate {
    /**
     * 
     * @type {number}
     * @memberof PaymentPlanDate
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof PaymentPlanDate
     */
    'payDate': string;
    /**
     * 
     * @type {number}
     * @memberof PaymentPlanDate
     */
    'percentage': number;
}
/**
 * 
 * @export
 * @interface PaymentPlanDateReplacementRequest
 */
export interface PaymentPlanDateReplacementRequest {
    /**
     * 
     * @type {Array<PaymentPlanReplacementDate>}
     * @memberof PaymentPlanDateReplacementRequest
     */
    'dates': Array<PaymentPlanReplacementDate>;
}
/**
 * 
 * @export
 * @interface PaymentPlanReplacementDate
 */
export interface PaymentPlanReplacementDate {
    /**
     * 
     * @type {string}
     * @memberof PaymentPlanReplacementDate
     */
    'payDate': string;
    /**
     * 
     * @type {number}
     * @memberof PaymentPlanReplacementDate
     */
    'percentage'?: number;
}
/**
 * 
 * @export
 * @interface PaymentPlanStoreReplacementRequest
 */
export interface PaymentPlanStoreReplacementRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof PaymentPlanStoreReplacementRequest
     */
    'stores': Array<number>;
}
/**
 * 
 * @export
 * @interface PaymentPlanStoreResponse
 */
export interface PaymentPlanStoreResponse {
    /**
     * 
     * @type {Array<number>}
     * @memberof PaymentPlanStoreResponse
     */
    'stores': Array<number>;
}
/**
 * 
 * @export
 * @interface PostTransactionRequest
 */
export interface PostTransactionRequest {
    /**
     * 
     * @type {number}
     * @memberof PostTransactionRequest
     */
    'accountId': number;
    /**
     * 
     * @type {string}
     * @memberof PostTransactionRequest
     */
    'memo': string;
    /**
     * 
     * @type {string}
     * @memberof PostTransactionRequest
     */
    'paymentType': string;
    /**
     * 
     * @type {number}
     * @memberof PostTransactionRequest
     */
    'value': number;
}
/**
 * 
 * @export
 * @interface PrintQueueRequest
 */
export interface PrintQueueRequest {
    /**
     * 
     * @type {boolean}
     * @memberof PrintQueueRequest
     */
    'overrideDormCheck'?: boolean;
    /**
     * 
     * @type {PrintQueueSortMethod}
     * @memberof PrintQueueRequest
     */
    'sortMethod': PrintQueueSortMethod;
    /**
     * 
     * @type {number}
     * @memberof PrintQueueRequest
     */
    'storeId': number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const PrintQueueSortMethod = {
    Name: 'name',
    Dorm: 'dorm'
} as const;

export type PrintQueueSortMethod = typeof PrintQueueSortMethod[keyof typeof PrintQueueSortMethod];


/**
 * 
 * @export
 * @interface PrintQueueStore
 */
export interface PrintQueueStore {
    /**
     * 
     * @type {number}
     * @memberof PrintQueueStore
     */
    'facultyCount': number;
    /**
     * 
     * @type {number}
     * @memberof PrintQueueStore
     */
    'storeId': number;
    /**
     * 
     * @type {string}
     * @memberof PrintQueueStore
     */
    'storeName': string;
    /**
     * 
     * @type {number}
     * @memberof PrintQueueStore
     */
    'studentCount': number;
}
/**
 * 
 * @export
 * @interface PrintQueueStoreSet
 */
export interface PrintQueueStoreSet {
    /**
     * 
     * @type {Array<PrintQueueStore>}
     * @memberof PrintQueueStoreSet
     */
    'stores': Array<PrintQueueStore>;
}
/**
 * 
 * @export
 * @interface Proration
 */
export interface Proration {
    /**
     * 
     * @type {number}
     * @memberof Proration
     */
    'cost'?: number;
    /**
     * 
     * @type {string}
     * @memberof Proration
     */
    'date': string;
    /**
     * 
     * @type {number}
     * @memberof Proration
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface ProrationSet
 */
export interface ProrationSet {
    /**
     * 
     * @type {Array<Proration>}
     * @memberof ProrationSet
     */
    'prorations': Array<Proration>;
}
/**
 * 
 * @export
 * @interface RentalCount
 */
export interface RentalCount {
    /**
     * 
     * @type {number}
     * @memberof RentalCount
     */
    'categoryId': number;
    /**
     * 
     * @type {string}
     * @memberof RentalCount
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof RentalCount
     */
    'value'?: number;
}
/**
 * 
 * @export
 * @interface RentalCountSet
 */
export interface RentalCountSet {
    /**
     * 
     * @type {Array<RentalCount>}
     * @memberof RentalCountSet
     */
    'counts': Array<RentalCount>;
}
/**
 * 
 * @export
 * @interface RollupRequest
 */
export interface RollupRequest {
    /**
     * 
     * @type {string}
     * @memberof RollupRequest
     */
    'endDate': string;
    /**
     * 
     * @type {string}
     * @memberof RollupRequest
     */
    'startDate': string;
    /**
     * 
     * @type {number}
     * @memberof RollupRequest
     */
    'storeId': number;
}
/**
 * 
 * @export
 * @interface ServerError
 */
export interface ServerError {
    /**
     * 
     * @type {string}
     * @memberof ServerError
     */
    'additionalData'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ServerError
     */
    'detail': string;
    /**
     * 
     * @type {string}
     * @memberof ServerError
     */
    'instance': string;
    /**
     * 
     * @type {number}
     * @memberof ServerError
     */
    'status': number;
    /**
     * 
     * @type {string}
     * @memberof ServerError
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof ServerError
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface Store
 */
export interface Store {
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    'addressLineOne': string;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    'addressLineTwo': string;
    /**
     * 
     * @type {number}
     * @memberof Store
     */
    'bagsPerWeek': number;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    'city': string;
    /**
     * 
     * @type {number}
     * @memberof Store
     */
    'commercialRollupAccount': number;
    /**
     * 
     * @type {boolean}
     * @memberof Store
     */
    'commercialRollupEnabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    'custom1': string;
    /**
     * 
     * @type {CustomTypes}
     * @memberof Store
     */
    'custom1Type': CustomTypes;
    /**
     * 
     * @type {DispatchSortOrder}
     * @memberof Store
     */
    'dispatchSortOrder': DispatchSortOrder;
    /**
     * 
     * @type {number}
     * @memberof Store
     */
    'facultyRollupAccount': number;
    /**
     * 
     * @type {boolean}
     * @memberof Store
     */
    'facultyRollupEnabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    'fullName': string;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    'generalKnowledge': string;
    /**
     * 
     * @type {boolean}
     * @memberof Store
     */
    'hidePricing': boolean;
    /**
     * 
     * @type {number}
     * @memberof Store
     */
    'id': number;
    /**
     * 
     * @type {boolean}
     * @memberof Store
     */
    'isActive': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Store
     */
    'isCustom1Enabled': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Store
     */
    'isLocationEnabledOnOrder': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Store
     */
    'isLocationMandatoryOnOrder': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Store
     */
    'isPassphraseRequired': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Store
     */
    'isRoomEnabledOnOrder': boolean;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    'notes': string;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    'passphrase': string;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    'postal': string;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    'prefix': string;
    /**
     * 
     * @type {boolean}
     * @memberof Store
     */
    'prorationEnabled': boolean;
    /**
     * 
     * @type {number}
     * @memberof Store
     */
    'rollupAccount': number;
    /**
     * 
     * @type {boolean}
     * @memberof Store
     */
    'rollupEnabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    'shortName': string;
    /**
     * 
     * @type {boolean}
     * @memberof Store
     */
    'showTOS': boolean;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    'specifics': string;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    'state': string;
    /**
     * 
     * @type {StoreType}
     * @memberof Store
     */
    'storeType': StoreType;
    /**
     * 
     * @type {number}
     * @memberof Store
     */
    'taxRate': number;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    'termsOfService': string;
    /**
     * 
     * @type {boolean}
     * @memberof Store
     */
    'useLocations': boolean;
}
/**
 * 
 * @export
 * @interface StorePlan
 */
export interface StorePlan {
    /**
     * 
     * @type {number}
     * @memberof StorePlan
     */
    'currentCost': number;
    /**
     * 
     * @type {number}
     * @memberof StorePlan
     */
    'id': number;
    /**
     * 
     * @type {boolean}
     * @memberof StorePlan
     */
    'isAvailableAsAddon': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StorePlan
     */
    'isFaculty': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StorePlan
     */
    'isProrationShutoff': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StorePlan
     */
    'isRenewal': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StorePlan
     */
    'isVisible': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StorePlan
     */
    'monthlyChargedPlan': boolean;
    /**
     * 
     * @type {string}
     * @memberof StorePlan
     */
    'publicName': string;
    /**
     * 
     * @type {string}
     * @memberof StorePlan
     */
    'referenceName': string;
    /**
     * 
     * @type {number}
     * @memberof StorePlan
     */
    'storeCost': number;
    /**
     * 
     * @type {number}
     * @memberof StorePlan
     */
    'visualOrder': number;
    /**
     * 
     * @type {number}
     * @memberof StorePlan
     */
    'weekCount': number;
}
/**
 * 
 * @export
 * @interface StorePlanAddon
 */
export interface StorePlanAddon {
    /**
     * 
     * @type {number}
     * @memberof StorePlanAddon
     */
    'cost': number;
    /**
     * 
     * @type {boolean}
     * @memberof StorePlanAddon
     */
    'isEnabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof StorePlanAddon
     */
    'name': string;
    /**
     * 
     * @type {OverrideType}
     * @memberof StorePlanAddon
     */
    'overrideType': OverrideType;
    /**
     * 
     * @type {number}
     * @memberof StorePlanAddon
     */
    'overrideValue': number;
    /**
     * 
     * @type {number}
     * @memberof StorePlanAddon
     */
    'planId': number;
    /**
     * 
     * @type {number}
     * @memberof StorePlanAddon
     */
    'storeCost': number;
    /**
     * 
     * @type {OverrideType}
     * @memberof StorePlanAddon
     */
    'storeOverrideType': OverrideType;
    /**
     * 
     * @type {number}
     * @memberof StorePlanAddon
     */
    'storeOverrideValue': number;
}
/**
 * 
 * @export
 * @interface StorePlanAddonChangeRequest
 */
export interface StorePlanAddonChangeRequest {
    /**
     * 
     * @type {number}
     * @memberof StorePlanAddonChangeRequest
     */
    'cost'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof StorePlanAddonChangeRequest
     */
    'isEnabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof StorePlanAddonChangeRequest
     */
    'name': string;
    /**
     * 
     * @type {OverrideType}
     * @memberof StorePlanAddonChangeRequest
     */
    'overrideType': OverrideType;
    /**
     * 
     * @type {number}
     * @memberof StorePlanAddonChangeRequest
     */
    'overrideValue'?: number;
    /**
     * 
     * @type {number}
     * @memberof StorePlanAddonChangeRequest
     */
    'planId': number;
    /**
     * 
     * @type {number}
     * @memberof StorePlanAddonChangeRequest
     */
    'storeCost'?: number;
    /**
     * 
     * @type {OverrideType}
     * @memberof StorePlanAddonChangeRequest
     */
    'storeOverrideType': OverrideType;
    /**
     * 
     * @type {number}
     * @memberof StorePlanAddonChangeRequest
     */
    'storeOverrideValue'?: number;
}
/**
 * 
 * @export
 * @interface StorePlanAddonChangeRequestSet
 */
export interface StorePlanAddonChangeRequestSet {
    /**
     * 
     * @type {Array<StorePlanAddonChangeRequest>}
     * @memberof StorePlanAddonChangeRequestSet
     */
    'addons': Array<StorePlanAddonChangeRequest>;
}
/**
 * 
 * @export
 * @interface StorePlanAddonSet
 */
export interface StorePlanAddonSet {
    /**
     * 
     * @type {Array<StorePlanAddon>}
     * @memberof StorePlanAddonSet
     */
    'addons': Array<StorePlanAddon>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const StoreType = {
    School: 'school',
    SummerSchool: 'summer_school',
    Camp: 'camp',
    RetailStore: 'retail_store',
    RetailRoute: 'retail_route'
} as const;

export type StoreType = typeof StoreType[keyof typeof StoreType];


/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {Array<string>}
     * @memberof User
     */
    'claims': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'displayName': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'id': string;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'loggedInRecently': boolean;
}
/**
 * 
 * @export
 * @interface UserClaimsRequest
 */
export interface UserClaimsRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof UserClaimsRequest
     */
    'claims': Array<string>;
}
/**
 * 
 * @export
 * @interface UserCreationRequest
 */
export interface UserCreationRequest {
    /**
     * 
     * @type {string}
     * @memberof UserCreationRequest
     */
    'displayName': string;
    /**
     * 
     * @type {string}
     * @memberof UserCreationRequest
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface UserSet
 */
export interface UserSet {
    /**
     * 
     * @type {Array<User>}
     * @memberof UserSet
     */
    'users': Array<User>;
}

/**
 * AccountsApi - axios parameter creator
 * @export
 */
export const AccountsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsAddBag: async (accountId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('accountsAddBag', 'accountId', accountId)
            const localVarPath = `/api/v2/data/accounts/{accountId}/bags`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} accountId 
         * @param {number} bagId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsAddToPrintQueue: async (accountId: number, bagId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('accountsAddToPrintQueue', 'accountId', accountId)
            // verify required parameter 'bagId' is not null or undefined
            assertParamExists('accountsAddToPrintQueue', 'bagId', bagId)
            const localVarPath = `/api/v2/data/accounts/{accountId}/bags/{bagId}/print`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"bagId"}}`, encodeURIComponent(String(bagId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} accountId 
         * @param {CreateAccountUserRequest} createAccountUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsAddUser: async (accountId: number, createAccountUserRequest: CreateAccountUserRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('accountsAddUser', 'accountId', accountId)
            // verify required parameter 'createAccountUserRequest' is not null or undefined
            assertParamExists('accountsAddUser', 'createAccountUserRequest', createAccountUserRequest)
            const localVarPath = `/api/v2/data/accounts/{accountId}/users`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAccountUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} accountId 
         * @param {OrderCancellationRequest} orderCancellationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsCancelOrder: async (accountId: number, orderCancellationRequest: OrderCancellationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('accountsCancelOrder', 'accountId', accountId)
            // verify required parameter 'orderCancellationRequest' is not null or undefined
            assertParamExists('accountsCancelOrder', 'orderCancellationRequest', orderCancellationRequest)
            const localVarPath = `/api/v2/data/accounts/{accountId}/orders`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(orderCancellationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateAccountRequest} createAccountRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsCreate: async (createAccountRequest: CreateAccountRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createAccountRequest' is not null or undefined
            assertParamExists('accountsCreate', 'createAccountRequest', createAccountRequest)
            const localVarPath = `/api/v2/data/accounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAccountRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} accountId 
         * @param {number} planId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsDisablePlan: async (accountId: number, planId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('accountsDisablePlan', 'accountId', accountId)
            // verify required parameter 'planId' is not null or undefined
            assertParamExists('accountsDisablePlan', 'planId', planId)
            const localVarPath = `/api/v2/data/accounts/{accountId}/plans/{planId}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"planId"}}`, encodeURIComponent(String(planId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} checklistId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsDownloadChecklist: async (id: number, checklistId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('accountsDownloadChecklist', 'id', id)
            // verify required parameter 'checklistId' is not null or undefined
            assertParamExists('accountsDownloadChecklist', 'checklistId', checklistId)
            const localVarPath = `/api/v2/data/accounts/{id}/checklists/{checklistId}/file`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"checklistId"}}`, encodeURIComponent(String(checklistId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {AccountPlanEnableRequest} accountPlanEnableRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsEnablePlan: async (id: number, accountPlanEnableRequest: AccountPlanEnableRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('accountsEnablePlan', 'id', id)
            // verify required parameter 'accountPlanEnableRequest' is not null or undefined
            assertParamExists('accountsEnablePlan', 'accountPlanEnableRequest', accountPlanEnableRequest)
            const localVarPath = `/api/v2/data/accounts/{id}/plans`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(accountPlanEnableRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('accountsGet', 'id', id)
            const localVarPath = `/api/v2/data/accounts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsGetCardOnFle: async (accountId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('accountsGetCardOnFle', 'accountId', accountId)
            const localVarPath = `/api/v2/data/accounts/{accountId}/cardOnFile`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} filter 
         * @param {number} [storeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsList: async (filter: string, storeId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'filter' is not null or undefined
            assertParamExists('accountsList', 'filter', filter)
            const localVarPath = `/api/v2/data/accounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (storeId !== undefined) {
                localVarQueryParameter['storeId'] = storeId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsListBags: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('accountsListBags', 'id', id)
            const localVarPath = `/api/v2/data/accounts/{id}/bags`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsListChecklists: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('accountsListChecklists', 'id', id)
            const localVarPath = `/api/v2/data/accounts/{id}/checklists`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsListMonthlyPayments: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('accountsListMonthlyPayments', 'id', id)
            const localVarPath = `/api/v2/data/accounts/{id}/subscriptions`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsListOrders: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('accountsListOrders', 'id', id)
            const localVarPath = `/api/v2/data/accounts/{id}/orders`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsListPlans: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('accountsListPlans', 'id', id)
            const localVarPath = `/api/v2/data/accounts/{id}/plans`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsListUsers: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('accountsListUsers', 'id', id)
            const localVarPath = `/api/v2/data/accounts/{id}/users`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} accountId 
         * @param {number} bagId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsRemoveFromPrintQueue: async (accountId: number, bagId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('accountsRemoveFromPrintQueue', 'accountId', accountId)
            // verify required parameter 'bagId' is not null or undefined
            assertParamExists('accountsRemoveFromPrintQueue', 'bagId', bagId)
            const localVarPath = `/api/v2/data/accounts/{accountId}/bags/{bagId}/print`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"bagId"}}`, encodeURIComponent(String(bagId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} accountId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsRemoveUser: async (accountId: number, userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('accountsRemoveUser', 'accountId', accountId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('accountsRemoveUser', 'userId', userId)
            const localVarPath = `/api/v2/data/accounts/{accountId}/users/{userId}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} accountId 
         * @param {CreateCardOnFileRequest} createCardOnFileRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsReplaceCardOnFile: async (accountId: number, createCardOnFileRequest: CreateCardOnFileRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('accountsReplaceCardOnFile', 'accountId', accountId)
            // verify required parameter 'createCardOnFileRequest' is not null or undefined
            assertParamExists('accountsReplaceCardOnFile', 'createCardOnFileRequest', createCardOnFileRequest)
            const localVarPath = `/api/v2/data/accounts/{accountId}/cardOnFile`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCardOnFileRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} accountId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsResetPassword: async (accountId: number, userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('accountsResetPassword', 'accountId', accountId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('accountsResetPassword', 'userId', userId)
            const localVarPath = `/api/v2/data/accounts/{accountId}/users/{userId}/password`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} accountId 
         * @param {number} bagId 
         * @param {AccountBagToggleRequest} accountBagToggleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsToggleBag: async (accountId: number, bagId: number, accountBagToggleRequest: AccountBagToggleRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('accountsToggleBag', 'accountId', accountId)
            // verify required parameter 'bagId' is not null or undefined
            assertParamExists('accountsToggleBag', 'bagId', bagId)
            // verify required parameter 'accountBagToggleRequest' is not null or undefined
            assertParamExists('accountsToggleBag', 'accountBagToggleRequest', accountBagToggleRequest)
            const localVarPath = `/api/v2/data/accounts/{accountId}/bags/{bagId}/isEnabled`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"bagId"}}`, encodeURIComponent(String(bagId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(accountBagToggleRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {PatchRequest} patchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsUpdate: async (id: number, patchRequest: PatchRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('accountsUpdate', 'id', id)
            // verify required parameter 'patchRequest' is not null or undefined
            assertParamExists('accountsUpdate', 'patchRequest', patchRequest)
            const localVarPath = `/api/v2/data/accounts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountsApi - functional programming interface
 * @export
 */
export const AccountsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccountsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsAddBag(accountId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountBag>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsAddBag(accountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} accountId 
         * @param {number} bagId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsAddToPrintQueue(accountId: number, bagId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsAddToPrintQueue(accountId, bagId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} accountId 
         * @param {CreateAccountUserRequest} createAccountUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsAddUser(accountId: number, createAccountUserRequest: CreateAccountUserRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsAddUser(accountId, createAccountUserRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} accountId 
         * @param {OrderCancellationRequest} orderCancellationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsCancelOrder(accountId: number, orderCancellationRequest: OrderCancellationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsCancelOrder(accountId, orderCancellationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateAccountRequest} createAccountRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsCreate(createAccountRequest: CreateAccountRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Account>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsCreate(createAccountRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} accountId 
         * @param {number} planId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsDisablePlan(accountId: number, planId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsDisablePlan(accountId, planId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} checklistId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsDownloadChecklist(id: number, checklistId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsDownloadChecklist(id, checklistId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {AccountPlanEnableRequest} accountPlanEnableRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsEnablePlan(id: number, accountPlanEnableRequest: AccountPlanEnableRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountPlan>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsEnablePlan(id, accountPlanEnableRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Account>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsGetCardOnFle(accountId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CardOnFile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsGetCardOnFle(accountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} filter 
         * @param {number} [storeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsList(filter: string, storeId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MinimalAccountSet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsList(filter, storeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsListBags(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountBagSet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsListBags(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsListChecklists(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChecklistSet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsListChecklists(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsListMonthlyPayments(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MonthlyPaymentSet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsListMonthlyPayments(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsListOrders(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountOrderSet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsListOrders(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsListPlans(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountPlanSet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsListPlans(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsListUsers(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountUserSet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsListUsers(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} accountId 
         * @param {number} bagId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsRemoveFromPrintQueue(accountId: number, bagId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsRemoveFromPrintQueue(accountId, bagId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} accountId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsRemoveUser(accountId: number, userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsRemoveUser(accountId, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} accountId 
         * @param {CreateCardOnFileRequest} createCardOnFileRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsReplaceCardOnFile(accountId: number, createCardOnFileRequest: CreateCardOnFileRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CardOnFile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsReplaceCardOnFile(accountId, createCardOnFileRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} accountId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsResetPassword(accountId: number, userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsResetPassword(accountId, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} accountId 
         * @param {number} bagId 
         * @param {AccountBagToggleRequest} accountBagToggleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsToggleBag(accountId: number, bagId: number, accountBagToggleRequest: AccountBagToggleRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountBag>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsToggleBag(accountId, bagId, accountBagToggleRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {PatchRequest} patchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsUpdate(id: number, patchRequest: PatchRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Account>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsUpdate(id, patchRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AccountsApi - factory interface
 * @export
 */
export const AccountsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccountsApiFp(configuration)
    return {
        /**
         * 
         * @param {number} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsAddBag(accountId: number, options?: any): AxiosPromise<AccountBag> {
            return localVarFp.accountsAddBag(accountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} accountId 
         * @param {number} bagId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsAddToPrintQueue(accountId: number, bagId: number, options?: any): AxiosPromise<void> {
            return localVarFp.accountsAddToPrintQueue(accountId, bagId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} accountId 
         * @param {CreateAccountUserRequest} createAccountUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsAddUser(accountId: number, createAccountUserRequest: CreateAccountUserRequest, options?: any): AxiosPromise<AccountUser> {
            return localVarFp.accountsAddUser(accountId, createAccountUserRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} accountId 
         * @param {OrderCancellationRequest} orderCancellationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsCancelOrder(accountId: number, orderCancellationRequest: OrderCancellationRequest, options?: any): AxiosPromise<void> {
            return localVarFp.accountsCancelOrder(accountId, orderCancellationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateAccountRequest} createAccountRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsCreate(createAccountRequest: CreateAccountRequest, options?: any): AxiosPromise<Account> {
            return localVarFp.accountsCreate(createAccountRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} accountId 
         * @param {number} planId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsDisablePlan(accountId: number, planId: number, options?: any): AxiosPromise<void> {
            return localVarFp.accountsDisablePlan(accountId, planId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} checklistId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsDownloadChecklist(id: number, checklistId: number, options?: any): AxiosPromise<File> {
            return localVarFp.accountsDownloadChecklist(id, checklistId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {AccountPlanEnableRequest} accountPlanEnableRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsEnablePlan(id: number, accountPlanEnableRequest: AccountPlanEnableRequest, options?: any): AxiosPromise<AccountPlan> {
            return localVarFp.accountsEnablePlan(id, accountPlanEnableRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsGet(id: number, options?: any): AxiosPromise<Account> {
            return localVarFp.accountsGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsGetCardOnFle(accountId: number, options?: any): AxiosPromise<CardOnFile> {
            return localVarFp.accountsGetCardOnFle(accountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} filter 
         * @param {number} [storeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsList(filter: string, storeId?: number, options?: any): AxiosPromise<MinimalAccountSet> {
            return localVarFp.accountsList(filter, storeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsListBags(id: number, options?: any): AxiosPromise<AccountBagSet> {
            return localVarFp.accountsListBags(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsListChecklists(id: number, options?: any): AxiosPromise<ChecklistSet> {
            return localVarFp.accountsListChecklists(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsListMonthlyPayments(id: number, options?: any): AxiosPromise<MonthlyPaymentSet> {
            return localVarFp.accountsListMonthlyPayments(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsListOrders(id: number, options?: any): AxiosPromise<AccountOrderSet> {
            return localVarFp.accountsListOrders(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsListPlans(id: number, options?: any): AxiosPromise<AccountPlanSet> {
            return localVarFp.accountsListPlans(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsListUsers(id: number, options?: any): AxiosPromise<AccountUserSet> {
            return localVarFp.accountsListUsers(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} accountId 
         * @param {number} bagId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsRemoveFromPrintQueue(accountId: number, bagId: number, options?: any): AxiosPromise<void> {
            return localVarFp.accountsRemoveFromPrintQueue(accountId, bagId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} accountId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsRemoveUser(accountId: number, userId: string, options?: any): AxiosPromise<void> {
            return localVarFp.accountsRemoveUser(accountId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} accountId 
         * @param {CreateCardOnFileRequest} createCardOnFileRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsReplaceCardOnFile(accountId: number, createCardOnFileRequest: CreateCardOnFileRequest, options?: any): AxiosPromise<CardOnFile> {
            return localVarFp.accountsReplaceCardOnFile(accountId, createCardOnFileRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} accountId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsResetPassword(accountId: number, userId: string, options?: any): AxiosPromise<void> {
            return localVarFp.accountsResetPassword(accountId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} accountId 
         * @param {number} bagId 
         * @param {AccountBagToggleRequest} accountBagToggleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsToggleBag(accountId: number, bagId: number, accountBagToggleRequest: AccountBagToggleRequest, options?: any): AxiosPromise<AccountBag> {
            return localVarFp.accountsToggleBag(accountId, bagId, accountBagToggleRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {PatchRequest} patchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsUpdate(id: number, patchRequest: PatchRequest, options?: any): AxiosPromise<Account> {
            return localVarFp.accountsUpdate(id, patchRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccountsApi - interface
 * @export
 * @interface AccountsApi
 */
export interface AccountsApiInterface {
    /**
     * 
     * @param {number} accountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApiInterface
     */
    accountsAddBag(accountId: number, options?: AxiosRequestConfig): AxiosPromise<AccountBag>;

    /**
     * 
     * @param {number} accountId 
     * @param {number} bagId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApiInterface
     */
    accountsAddToPrintQueue(accountId: number, bagId: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {number} accountId 
     * @param {CreateAccountUserRequest} createAccountUserRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApiInterface
     */
    accountsAddUser(accountId: number, createAccountUserRequest: CreateAccountUserRequest, options?: AxiosRequestConfig): AxiosPromise<AccountUser>;

    /**
     * 
     * @param {number} accountId 
     * @param {OrderCancellationRequest} orderCancellationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApiInterface
     */
    accountsCancelOrder(accountId: number, orderCancellationRequest: OrderCancellationRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {CreateAccountRequest} createAccountRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApiInterface
     */
    accountsCreate(createAccountRequest: CreateAccountRequest, options?: AxiosRequestConfig): AxiosPromise<Account>;

    /**
     * 
     * @param {number} accountId 
     * @param {number} planId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApiInterface
     */
    accountsDisablePlan(accountId: number, planId: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {number} id 
     * @param {number} checklistId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApiInterface
     */
    accountsDownloadChecklist(id: number, checklistId: number, options?: AxiosRequestConfig): AxiosPromise<File>;

    /**
     * 
     * @param {number} id 
     * @param {AccountPlanEnableRequest} accountPlanEnableRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApiInterface
     */
    accountsEnablePlan(id: number, accountPlanEnableRequest: AccountPlanEnableRequest, options?: AxiosRequestConfig): AxiosPromise<AccountPlan>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApiInterface
     */
    accountsGet(id: number, options?: AxiosRequestConfig): AxiosPromise<Account>;

    /**
     * 
     * @param {number} accountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApiInterface
     */
    accountsGetCardOnFle(accountId: number, options?: AxiosRequestConfig): AxiosPromise<CardOnFile>;

    /**
     * 
     * @param {string} filter 
     * @param {number} [storeId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApiInterface
     */
    accountsList(filter: string, storeId?: number, options?: AxiosRequestConfig): AxiosPromise<MinimalAccountSet>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApiInterface
     */
    accountsListBags(id: number, options?: AxiosRequestConfig): AxiosPromise<AccountBagSet>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApiInterface
     */
    accountsListChecklists(id: number, options?: AxiosRequestConfig): AxiosPromise<ChecklistSet>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApiInterface
     */
    accountsListMonthlyPayments(id: number, options?: AxiosRequestConfig): AxiosPromise<MonthlyPaymentSet>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApiInterface
     */
    accountsListOrders(id: number, options?: AxiosRequestConfig): AxiosPromise<AccountOrderSet>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApiInterface
     */
    accountsListPlans(id: number, options?: AxiosRequestConfig): AxiosPromise<AccountPlanSet>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApiInterface
     */
    accountsListUsers(id: number, options?: AxiosRequestConfig): AxiosPromise<AccountUserSet>;

    /**
     * 
     * @param {number} accountId 
     * @param {number} bagId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApiInterface
     */
    accountsRemoveFromPrintQueue(accountId: number, bagId: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {number} accountId 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApiInterface
     */
    accountsRemoveUser(accountId: number, userId: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {number} accountId 
     * @param {CreateCardOnFileRequest} createCardOnFileRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApiInterface
     */
    accountsReplaceCardOnFile(accountId: number, createCardOnFileRequest: CreateCardOnFileRequest, options?: AxiosRequestConfig): AxiosPromise<CardOnFile>;

    /**
     * 
     * @param {number} accountId 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApiInterface
     */
    accountsResetPassword(accountId: number, userId: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {number} accountId 
     * @param {number} bagId 
     * @param {AccountBagToggleRequest} accountBagToggleRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApiInterface
     */
    accountsToggleBag(accountId: number, bagId: number, accountBagToggleRequest: AccountBagToggleRequest, options?: AxiosRequestConfig): AxiosPromise<AccountBag>;

    /**
     * 
     * @param {number} id 
     * @param {PatchRequest} patchRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApiInterface
     */
    accountsUpdate(id: number, patchRequest: PatchRequest, options?: AxiosRequestConfig): AxiosPromise<Account>;

}

/**
 * AccountsApi - object-oriented interface
 * @export
 * @class AccountsApi
 * @extends {BaseAPI}
 */
export class AccountsApi extends BaseAPI implements AccountsApiInterface {
    /**
     * 
     * @param {number} accountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsAddBag(accountId: number, options?: AxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsAddBag(accountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} accountId 
     * @param {number} bagId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsAddToPrintQueue(accountId: number, bagId: number, options?: AxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsAddToPrintQueue(accountId, bagId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} accountId 
     * @param {CreateAccountUserRequest} createAccountUserRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsAddUser(accountId: number, createAccountUserRequest: CreateAccountUserRequest, options?: AxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsAddUser(accountId, createAccountUserRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} accountId 
     * @param {OrderCancellationRequest} orderCancellationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsCancelOrder(accountId: number, orderCancellationRequest: OrderCancellationRequest, options?: AxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsCancelOrder(accountId, orderCancellationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateAccountRequest} createAccountRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsCreate(createAccountRequest: CreateAccountRequest, options?: AxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsCreate(createAccountRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} accountId 
     * @param {number} planId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsDisablePlan(accountId: number, planId: number, options?: AxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsDisablePlan(accountId, planId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {number} checklistId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsDownloadChecklist(id: number, checklistId: number, options?: AxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsDownloadChecklist(id, checklistId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {AccountPlanEnableRequest} accountPlanEnableRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsEnablePlan(id: number, accountPlanEnableRequest: AccountPlanEnableRequest, options?: AxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsEnablePlan(id, accountPlanEnableRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsGet(id: number, options?: AxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} accountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsGetCardOnFle(accountId: number, options?: AxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsGetCardOnFle(accountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} filter 
     * @param {number} [storeId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsList(filter: string, storeId?: number, options?: AxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsList(filter, storeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsListBags(id: number, options?: AxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsListBags(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsListChecklists(id: number, options?: AxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsListChecklists(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsListMonthlyPayments(id: number, options?: AxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsListMonthlyPayments(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsListOrders(id: number, options?: AxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsListOrders(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsListPlans(id: number, options?: AxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsListPlans(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsListUsers(id: number, options?: AxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsListUsers(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} accountId 
     * @param {number} bagId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsRemoveFromPrintQueue(accountId: number, bagId: number, options?: AxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsRemoveFromPrintQueue(accountId, bagId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} accountId 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsRemoveUser(accountId: number, userId: string, options?: AxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsRemoveUser(accountId, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} accountId 
     * @param {CreateCardOnFileRequest} createCardOnFileRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsReplaceCardOnFile(accountId: number, createCardOnFileRequest: CreateCardOnFileRequest, options?: AxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsReplaceCardOnFile(accountId, createCardOnFileRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} accountId 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsResetPassword(accountId: number, userId: string, options?: AxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsResetPassword(accountId, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} accountId 
     * @param {number} bagId 
     * @param {AccountBagToggleRequest} accountBagToggleRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsToggleBag(accountId: number, bagId: number, accountBagToggleRequest: AccountBagToggleRequest, options?: AxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsToggleBag(accountId, bagId, accountBagToggleRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {PatchRequest} patchRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsUpdate(id: number, patchRequest: PatchRequest, options?: AxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsUpdate(id, patchRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BagsApi - axios parameter creator
 * @export
 */
export const BagsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {PrintQueueRequest} printQueueRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bagsClearQueue: async (printQueueRequest: PrintQueueRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'printQueueRequest' is not null or undefined
            assertParamExists('bagsClearQueue', 'printQueueRequest', printQueueRequest)
            const localVarPath = `/api/v2/data/bags/printQueue`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(printQueueRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bagsListQueue: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/data/bags/printQueue`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PrintQueueRequest} printQueueRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bagsPrintQueueRequest: async (printQueueRequest: PrintQueueRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'printQueueRequest' is not null or undefined
            assertParamExists('bagsPrintQueueRequest', 'printQueueRequest', printQueueRequest)
            const localVarPath = `/api/v2/data/bags/printQueue`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(printQueueRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bagsReprintBag: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('bagsReprintBag', 'id', id)
            const localVarPath = `/api/v2/data/bags/reprint/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BagsApi - functional programming interface
 * @export
 */
export const BagsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BagsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {PrintQueueRequest} printQueueRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bagsClearQueue(printQueueRequest: PrintQueueRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bagsClearQueue(printQueueRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bagsListQueue(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PrintQueueStoreSet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bagsListQueue(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PrintQueueRequest} printQueueRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bagsPrintQueueRequest(printQueueRequest: PrintQueueRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bagsPrintQueueRequest(printQueueRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bagsReprintBag(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bagsReprintBag(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BagsApi - factory interface
 * @export
 */
export const BagsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BagsApiFp(configuration)
    return {
        /**
         * 
         * @param {PrintQueueRequest} printQueueRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bagsClearQueue(printQueueRequest: PrintQueueRequest, options?: any): AxiosPromise<void> {
            return localVarFp.bagsClearQueue(printQueueRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bagsListQueue(options?: any): AxiosPromise<PrintQueueStoreSet> {
            return localVarFp.bagsListQueue(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PrintQueueRequest} printQueueRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bagsPrintQueueRequest(printQueueRequest: PrintQueueRequest, options?: any): AxiosPromise<File> {
            return localVarFp.bagsPrintQueueRequest(printQueueRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bagsReprintBag(id: string, options?: any): AxiosPromise<File> {
            return localVarFp.bagsReprintBag(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BagsApi - interface
 * @export
 * @interface BagsApi
 */
export interface BagsApiInterface {
    /**
     * 
     * @param {PrintQueueRequest} printQueueRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BagsApiInterface
     */
    bagsClearQueue(printQueueRequest: PrintQueueRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BagsApiInterface
     */
    bagsListQueue(options?: AxiosRequestConfig): AxiosPromise<PrintQueueStoreSet>;

    /**
     * 
     * @param {PrintQueueRequest} printQueueRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BagsApiInterface
     */
    bagsPrintQueueRequest(printQueueRequest: PrintQueueRequest, options?: AxiosRequestConfig): AxiosPromise<File>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BagsApiInterface
     */
    bagsReprintBag(id: string, options?: AxiosRequestConfig): AxiosPromise<File>;

}

/**
 * BagsApi - object-oriented interface
 * @export
 * @class BagsApi
 * @extends {BaseAPI}
 */
export class BagsApi extends BaseAPI implements BagsApiInterface {
    /**
     * 
     * @param {PrintQueueRequest} printQueueRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BagsApi
     */
    public bagsClearQueue(printQueueRequest: PrintQueueRequest, options?: AxiosRequestConfig) {
        return BagsApiFp(this.configuration).bagsClearQueue(printQueueRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BagsApi
     */
    public bagsListQueue(options?: AxiosRequestConfig) {
        return BagsApiFp(this.configuration).bagsListQueue(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PrintQueueRequest} printQueueRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BagsApi
     */
    public bagsPrintQueueRequest(printQueueRequest: PrintQueueRequest, options?: AxiosRequestConfig) {
        return BagsApiFp(this.configuration).bagsPrintQueueRequest(printQueueRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BagsApi
     */
    public bagsReprintBag(id: string, options?: AxiosRequestConfig) {
        return BagsApiFp(this.configuration).bagsReprintBag(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BulkApi - axios parameter creator
 * @export
 */
export const BulkApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkBulkAssignPlans: async (file: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('bulkBulkAssignPlans', 'file', file)
            const localVarPath = `/api/v2/data/bulk/assignPlans`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {File} file 
         * @param {string} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkBulkCreateAccounts: async (file: File, storeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('bulkBulkCreateAccounts', 'file', file)
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('bulkBulkCreateAccounts', 'storeId', storeId)
            const localVarPath = `/api/v2/data/bulk/createAccounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
            if (storeId !== undefined) { 
                localVarFormParams.append('storeId', storeId as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CalcAndSyncRequest} calcAndSyncRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkCalcAndSync: async (calcAndSyncRequest: CalcAndSyncRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'calcAndSyncRequest' is not null or undefined
            assertParamExists('bulkCalcAndSync', 'calcAndSyncRequest', calcAndSyncRequest)
            const localVarPath = `/api/v2/data/bulk/calcAndSync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(calcAndSyncRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {OnAccountChargeRequest} onAccountChargeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkChargeAccounts: async (onAccountChargeRequest: OnAccountChargeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'onAccountChargeRequest' is not null or undefined
            assertParamExists('bulkChargeAccounts', 'onAccountChargeRequest', onAccountChargeRequest)
            const localVarPath = `/api/v2/data/bulk/onAccountCharges`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(onAccountChargeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkCheckProcessStatus: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('bulkCheckProcessStatus', 'id', id)
            const localVarPath = `/api/v2/data/bulk/processes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkDownloadAssignPlansTemplate: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/data/bulk/assignPlans/template`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkDownloadCreateAccountsTemplate: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/data/bulk/createAccounts/template`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {OnAccountChargeRequest} onAccountChargeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkDownloadOnAccountChargePreview: async (onAccountChargeRequest: OnAccountChargeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'onAccountChargeRequest' is not null or undefined
            assertParamExists('bulkDownloadOnAccountChargePreview', 'onAccountChargeRequest', onAccountChargeRequest)
            const localVarPath = `/api/v2/data/bulk/onAccountCharges/preview`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(onAccountChargeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkDownloadUpdateStorePlansTemplate: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/data/bulk/updateStorePlans/template`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkGetChargeResults: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('bulkGetChargeResults', 'id', id)
            const localVarPath = `/api/v2/data/bulk/onAccountCharges/results/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkGetCreateAccountsResults: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('bulkGetCreateAccountsResults', 'id', id)
            const localVarPath = `/api/v2/data/bulk/createAccounts/results/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MergeAccountsRequest} mergeAccountsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkMergeAccounts: async (mergeAccountsRequest: MergeAccountsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mergeAccountsRequest' is not null or undefined
            assertParamExists('bulkMergeAccounts', 'mergeAccountsRequest', mergeAccountsRequest)
            const localVarPath = `/api/v2/data/bulk/mergeAccounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mergeAccountsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PostTransactionRequest} postTransactionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkPostTransaction: async (postTransactionRequest: PostTransactionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'postTransactionRequest' is not null or undefined
            assertParamExists('bulkPostTransaction', 'postTransactionRequest', postTransactionRequest)
            const localVarPath = `/api/v2/data/bulk/postTransaction`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postTransactionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RollupRequest} rollupRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkRollupAccounts: async (rollupRequest: RollupRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'rollupRequest' is not null or undefined
            assertParamExists('bulkRollupAccounts', 'rollupRequest', rollupRequest)
            const localVarPath = `/api/v2/data/bulk/rollup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rollupRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkUpdateStorePlans: async (file: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('bulkUpdateStorePlans', 'file', file)
            const localVarPath = `/api/v2/data/bulk/updateStorePlans`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BulkApi - functional programming interface
 * @export
 */
export const BulkApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BulkApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bulkBulkAssignPlans(file: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LongRunningTaskIdResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bulkBulkAssignPlans(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {File} file 
         * @param {string} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bulkBulkCreateAccounts(file: File, storeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LongRunningTaskIdResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bulkBulkCreateAccounts(file, storeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CalcAndSyncRequest} calcAndSyncRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bulkCalcAndSync(calcAndSyncRequest: CalcAndSyncRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LongRunningTaskIdResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bulkCalcAndSync(calcAndSyncRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {OnAccountChargeRequest} onAccountChargeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bulkChargeAccounts(onAccountChargeRequest: OnAccountChargeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LongRunningTaskIdResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bulkChargeAccounts(onAccountChargeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bulkCheckProcessStatus(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LongRunningProcessStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bulkCheckProcessStatus(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bulkDownloadAssignPlansTemplate(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bulkDownloadAssignPlansTemplate(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bulkDownloadCreateAccountsTemplate(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bulkDownloadCreateAccountsTemplate(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {OnAccountChargeRequest} onAccountChargeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bulkDownloadOnAccountChargePreview(onAccountChargeRequest: OnAccountChargeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bulkDownloadOnAccountChargePreview(onAccountChargeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bulkDownloadUpdateStorePlansTemplate(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bulkDownloadUpdateStorePlansTemplate(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bulkGetChargeResults(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bulkGetChargeResults(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bulkGetCreateAccountsResults(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BulkAccountCreationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bulkGetCreateAccountsResults(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MergeAccountsRequest} mergeAccountsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bulkMergeAccounts(mergeAccountsRequest: MergeAccountsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LongRunningTaskIdResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bulkMergeAccounts(mergeAccountsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PostTransactionRequest} postTransactionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bulkPostTransaction(postTransactionRequest: PostTransactionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bulkPostTransaction(postTransactionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RollupRequest} rollupRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bulkRollupAccounts(rollupRequest: RollupRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LongRunningTaskIdResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bulkRollupAccounts(rollupRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bulkUpdateStorePlans(file: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LongRunningTaskIdResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bulkUpdateStorePlans(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BulkApi - factory interface
 * @export
 */
export const BulkApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BulkApiFp(configuration)
    return {
        /**
         * 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkBulkAssignPlans(file: File, options?: any): AxiosPromise<LongRunningTaskIdResponse> {
            return localVarFp.bulkBulkAssignPlans(file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {File} file 
         * @param {string} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkBulkCreateAccounts(file: File, storeId: string, options?: any): AxiosPromise<LongRunningTaskIdResponse> {
            return localVarFp.bulkBulkCreateAccounts(file, storeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CalcAndSyncRequest} calcAndSyncRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkCalcAndSync(calcAndSyncRequest: CalcAndSyncRequest, options?: any): AxiosPromise<LongRunningTaskIdResponse> {
            return localVarFp.bulkCalcAndSync(calcAndSyncRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OnAccountChargeRequest} onAccountChargeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkChargeAccounts(onAccountChargeRequest: OnAccountChargeRequest, options?: any): AxiosPromise<LongRunningTaskIdResponse> {
            return localVarFp.bulkChargeAccounts(onAccountChargeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkCheckProcessStatus(id: string, options?: any): AxiosPromise<LongRunningProcessStatus> {
            return localVarFp.bulkCheckProcessStatus(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkDownloadAssignPlansTemplate(options?: any): AxiosPromise<File> {
            return localVarFp.bulkDownloadAssignPlansTemplate(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkDownloadCreateAccountsTemplate(options?: any): AxiosPromise<File> {
            return localVarFp.bulkDownloadCreateAccountsTemplate(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OnAccountChargeRequest} onAccountChargeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkDownloadOnAccountChargePreview(onAccountChargeRequest: OnAccountChargeRequest, options?: any): AxiosPromise<File> {
            return localVarFp.bulkDownloadOnAccountChargePreview(onAccountChargeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkDownloadUpdateStorePlansTemplate(options?: any): AxiosPromise<File> {
            return localVarFp.bulkDownloadUpdateStorePlansTemplate(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkGetChargeResults(id: string, options?: any): AxiosPromise<File> {
            return localVarFp.bulkGetChargeResults(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkGetCreateAccountsResults(id: string, options?: any): AxiosPromise<BulkAccountCreationResponse> {
            return localVarFp.bulkGetCreateAccountsResults(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MergeAccountsRequest} mergeAccountsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkMergeAccounts(mergeAccountsRequest: MergeAccountsRequest, options?: any): AxiosPromise<LongRunningTaskIdResponse> {
            return localVarFp.bulkMergeAccounts(mergeAccountsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PostTransactionRequest} postTransactionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkPostTransaction(postTransactionRequest: PostTransactionRequest, options?: any): AxiosPromise<void> {
            return localVarFp.bulkPostTransaction(postTransactionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RollupRequest} rollupRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkRollupAccounts(rollupRequest: RollupRequest, options?: any): AxiosPromise<LongRunningTaskIdResponse> {
            return localVarFp.bulkRollupAccounts(rollupRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkUpdateStorePlans(file: File, options?: any): AxiosPromise<LongRunningTaskIdResponse> {
            return localVarFp.bulkUpdateStorePlans(file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BulkApi - interface
 * @export
 * @interface BulkApi
 */
export interface BulkApiInterface {
    /**
     * 
     * @param {File} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BulkApiInterface
     */
    bulkBulkAssignPlans(file: File, options?: AxiosRequestConfig): AxiosPromise<LongRunningTaskIdResponse>;

    /**
     * 
     * @param {File} file 
     * @param {string} storeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BulkApiInterface
     */
    bulkBulkCreateAccounts(file: File, storeId: string, options?: AxiosRequestConfig): AxiosPromise<LongRunningTaskIdResponse>;

    /**
     * 
     * @param {CalcAndSyncRequest} calcAndSyncRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BulkApiInterface
     */
    bulkCalcAndSync(calcAndSyncRequest: CalcAndSyncRequest, options?: AxiosRequestConfig): AxiosPromise<LongRunningTaskIdResponse>;

    /**
     * 
     * @param {OnAccountChargeRequest} onAccountChargeRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BulkApiInterface
     */
    bulkChargeAccounts(onAccountChargeRequest: OnAccountChargeRequest, options?: AxiosRequestConfig): AxiosPromise<LongRunningTaskIdResponse>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BulkApiInterface
     */
    bulkCheckProcessStatus(id: string, options?: AxiosRequestConfig): AxiosPromise<LongRunningProcessStatus>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BulkApiInterface
     */
    bulkDownloadAssignPlansTemplate(options?: AxiosRequestConfig): AxiosPromise<File>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BulkApiInterface
     */
    bulkDownloadCreateAccountsTemplate(options?: AxiosRequestConfig): AxiosPromise<File>;

    /**
     * 
     * @param {OnAccountChargeRequest} onAccountChargeRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BulkApiInterface
     */
    bulkDownloadOnAccountChargePreview(onAccountChargeRequest: OnAccountChargeRequest, options?: AxiosRequestConfig): AxiosPromise<File>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BulkApiInterface
     */
    bulkDownloadUpdateStorePlansTemplate(options?: AxiosRequestConfig): AxiosPromise<File>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BulkApiInterface
     */
    bulkGetChargeResults(id: string, options?: AxiosRequestConfig): AxiosPromise<File>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BulkApiInterface
     */
    bulkGetCreateAccountsResults(id: string, options?: AxiosRequestConfig): AxiosPromise<BulkAccountCreationResponse>;

    /**
     * 
     * @param {MergeAccountsRequest} mergeAccountsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BulkApiInterface
     */
    bulkMergeAccounts(mergeAccountsRequest: MergeAccountsRequest, options?: AxiosRequestConfig): AxiosPromise<LongRunningTaskIdResponse>;

    /**
     * 
     * @param {PostTransactionRequest} postTransactionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BulkApiInterface
     */
    bulkPostTransaction(postTransactionRequest: PostTransactionRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {RollupRequest} rollupRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BulkApiInterface
     */
    bulkRollupAccounts(rollupRequest: RollupRequest, options?: AxiosRequestConfig): AxiosPromise<LongRunningTaskIdResponse>;

    /**
     * 
     * @param {File} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BulkApiInterface
     */
    bulkUpdateStorePlans(file: File, options?: AxiosRequestConfig): AxiosPromise<LongRunningTaskIdResponse>;

}

/**
 * BulkApi - object-oriented interface
 * @export
 * @class BulkApi
 * @extends {BaseAPI}
 */
export class BulkApi extends BaseAPI implements BulkApiInterface {
    /**
     * 
     * @param {File} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BulkApi
     */
    public bulkBulkAssignPlans(file: File, options?: AxiosRequestConfig) {
        return BulkApiFp(this.configuration).bulkBulkAssignPlans(file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {File} file 
     * @param {string} storeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BulkApi
     */
    public bulkBulkCreateAccounts(file: File, storeId: string, options?: AxiosRequestConfig) {
        return BulkApiFp(this.configuration).bulkBulkCreateAccounts(file, storeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CalcAndSyncRequest} calcAndSyncRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BulkApi
     */
    public bulkCalcAndSync(calcAndSyncRequest: CalcAndSyncRequest, options?: AxiosRequestConfig) {
        return BulkApiFp(this.configuration).bulkCalcAndSync(calcAndSyncRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OnAccountChargeRequest} onAccountChargeRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BulkApi
     */
    public bulkChargeAccounts(onAccountChargeRequest: OnAccountChargeRequest, options?: AxiosRequestConfig) {
        return BulkApiFp(this.configuration).bulkChargeAccounts(onAccountChargeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BulkApi
     */
    public bulkCheckProcessStatus(id: string, options?: AxiosRequestConfig) {
        return BulkApiFp(this.configuration).bulkCheckProcessStatus(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BulkApi
     */
    public bulkDownloadAssignPlansTemplate(options?: AxiosRequestConfig) {
        return BulkApiFp(this.configuration).bulkDownloadAssignPlansTemplate(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BulkApi
     */
    public bulkDownloadCreateAccountsTemplate(options?: AxiosRequestConfig) {
        return BulkApiFp(this.configuration).bulkDownloadCreateAccountsTemplate(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OnAccountChargeRequest} onAccountChargeRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BulkApi
     */
    public bulkDownloadOnAccountChargePreview(onAccountChargeRequest: OnAccountChargeRequest, options?: AxiosRequestConfig) {
        return BulkApiFp(this.configuration).bulkDownloadOnAccountChargePreview(onAccountChargeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BulkApi
     */
    public bulkDownloadUpdateStorePlansTemplate(options?: AxiosRequestConfig) {
        return BulkApiFp(this.configuration).bulkDownloadUpdateStorePlansTemplate(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BulkApi
     */
    public bulkGetChargeResults(id: string, options?: AxiosRequestConfig) {
        return BulkApiFp(this.configuration).bulkGetChargeResults(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BulkApi
     */
    public bulkGetCreateAccountsResults(id: string, options?: AxiosRequestConfig) {
        return BulkApiFp(this.configuration).bulkGetCreateAccountsResults(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MergeAccountsRequest} mergeAccountsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BulkApi
     */
    public bulkMergeAccounts(mergeAccountsRequest: MergeAccountsRequest, options?: AxiosRequestConfig) {
        return BulkApiFp(this.configuration).bulkMergeAccounts(mergeAccountsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PostTransactionRequest} postTransactionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BulkApi
     */
    public bulkPostTransaction(postTransactionRequest: PostTransactionRequest, options?: AxiosRequestConfig) {
        return BulkApiFp(this.configuration).bulkPostTransaction(postTransactionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RollupRequest} rollupRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BulkApi
     */
    public bulkRollupAccounts(rollupRequest: RollupRequest, options?: AxiosRequestConfig) {
        return BulkApiFp(this.configuration).bulkRollupAccounts(rollupRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {File} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BulkApi
     */
    public bulkUpdateStorePlans(file: File, options?: AxiosRequestConfig) {
        return BulkApiFp(this.configuration).bulkUpdateStorePlans(file, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DepartmentsApi - axios parameter creator
 * @export
 */
export const DepartmentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {DepartmentCreateRequest} departmentCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        departmentsCreate: async (departmentCreateRequest: DepartmentCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'departmentCreateRequest' is not null or undefined
            assertParamExists('departmentsCreate', 'departmentCreateRequest', departmentCreateRequest)
            const localVarPath = `/api/v2/data/departments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(departmentCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        departmentsGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('departmentsGet', 'id', id)
            const localVarPath = `/api/v2/data/departments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        departmentsList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/data/departments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        departmentsListIcs: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('departmentsListIcs', 'id', id)
            const localVarPath = `/api/v2/data/departments/{id}/inventoryCategories`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {DepartmentICSetRequest} departmentICSetRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        departmentsSetIcs: async (id: number, departmentICSetRequest: DepartmentICSetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('departmentsSetIcs', 'id', id)
            // verify required parameter 'departmentICSetRequest' is not null or undefined
            assertParamExists('departmentsSetIcs', 'departmentICSetRequest', departmentICSetRequest)
            const localVarPath = `/api/v2/data/departments/{id}/ics`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(departmentICSetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DepartmentsApi - functional programming interface
 * @export
 */
export const DepartmentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DepartmentsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {DepartmentCreateRequest} departmentCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async departmentsCreate(departmentCreateRequest: DepartmentCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MinimalDepartment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.departmentsCreate(departmentCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async departmentsGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Department>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.departmentsGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async departmentsList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MinimalDepartmentSet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.departmentsList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async departmentsListIcs(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MinimalInventoryCategorySet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.departmentsListIcs(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {DepartmentICSetRequest} departmentICSetRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async departmentsSetIcs(id: number, departmentICSetRequest: DepartmentICSetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Department>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.departmentsSetIcs(id, departmentICSetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DepartmentsApi - factory interface
 * @export
 */
export const DepartmentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DepartmentsApiFp(configuration)
    return {
        /**
         * 
         * @param {DepartmentCreateRequest} departmentCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        departmentsCreate(departmentCreateRequest: DepartmentCreateRequest, options?: any): AxiosPromise<MinimalDepartment> {
            return localVarFp.departmentsCreate(departmentCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        departmentsGet(id: number, options?: any): AxiosPromise<Department> {
            return localVarFp.departmentsGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        departmentsList(options?: any): AxiosPromise<MinimalDepartmentSet> {
            return localVarFp.departmentsList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        departmentsListIcs(id: number, options?: any): AxiosPromise<MinimalInventoryCategorySet> {
            return localVarFp.departmentsListIcs(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {DepartmentICSetRequest} departmentICSetRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        departmentsSetIcs(id: number, departmentICSetRequest: DepartmentICSetRequest, options?: any): AxiosPromise<Department> {
            return localVarFp.departmentsSetIcs(id, departmentICSetRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DepartmentsApi - interface
 * @export
 * @interface DepartmentsApi
 */
export interface DepartmentsApiInterface {
    /**
     * 
     * @param {DepartmentCreateRequest} departmentCreateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsApiInterface
     */
    departmentsCreate(departmentCreateRequest: DepartmentCreateRequest, options?: AxiosRequestConfig): AxiosPromise<MinimalDepartment>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsApiInterface
     */
    departmentsGet(id: number, options?: AxiosRequestConfig): AxiosPromise<Department>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsApiInterface
     */
    departmentsList(options?: AxiosRequestConfig): AxiosPromise<MinimalDepartmentSet>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsApiInterface
     */
    departmentsListIcs(id: number, options?: AxiosRequestConfig): AxiosPromise<MinimalInventoryCategorySet>;

    /**
     * 
     * @param {number} id 
     * @param {DepartmentICSetRequest} departmentICSetRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsApiInterface
     */
    departmentsSetIcs(id: number, departmentICSetRequest: DepartmentICSetRequest, options?: AxiosRequestConfig): AxiosPromise<Department>;

}

/**
 * DepartmentsApi - object-oriented interface
 * @export
 * @class DepartmentsApi
 * @extends {BaseAPI}
 */
export class DepartmentsApi extends BaseAPI implements DepartmentsApiInterface {
    /**
     * 
     * @param {DepartmentCreateRequest} departmentCreateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsApi
     */
    public departmentsCreate(departmentCreateRequest: DepartmentCreateRequest, options?: AxiosRequestConfig) {
        return DepartmentsApiFp(this.configuration).departmentsCreate(departmentCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsApi
     */
    public departmentsGet(id: number, options?: AxiosRequestConfig) {
        return DepartmentsApiFp(this.configuration).departmentsGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsApi
     */
    public departmentsList(options?: AxiosRequestConfig) {
        return DepartmentsApiFp(this.configuration).departmentsList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsApi
     */
    public departmentsListIcs(id: number, options?: AxiosRequestConfig) {
        return DepartmentsApiFp(this.configuration).departmentsListIcs(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {DepartmentICSetRequest} departmentICSetRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsApi
     */
    public departmentsSetIcs(id: number, departmentICSetRequest: DepartmentICSetRequest, options?: AxiosRequestConfig) {
        return DepartmentsApiFp(this.configuration).departmentsSetIcs(id, departmentICSetRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DiscountsApi - axios parameter creator
 * @export
 */
export const DiscountsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {DiscountCreationRequest} discountCreationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discountsCreate: async (discountCreationRequest: DiscountCreationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'discountCreationRequest' is not null or undefined
            assertParamExists('discountsCreate', 'discountCreationRequest', discountCreationRequest)
            const localVarPath = `/api/v2/data/discounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(discountCreationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discountsGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('discountsGet', 'id', id)
            const localVarPath = `/api/v2/data/discounts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discountsList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/data/discounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {PatchRequest} patchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discountsUpdate: async (id: string, patchRequest: PatchRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('discountsUpdate', 'id', id)
            // verify required parameter 'patchRequest' is not null or undefined
            assertParamExists('discountsUpdate', 'patchRequest', patchRequest)
            const localVarPath = `/api/v2/data/discounts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DiscountsApi - functional programming interface
 * @export
 */
export const DiscountsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DiscountsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {DiscountCreationRequest} discountCreationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async discountsCreate(discountCreationRequest: DiscountCreationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MinimalDiscount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.discountsCreate(discountCreationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async discountsGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Discount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.discountsGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async discountsList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MinimalDiscountSet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.discountsList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {PatchRequest} patchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async discountsUpdate(id: string, patchRequest: PatchRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Discount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.discountsUpdate(id, patchRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DiscountsApi - factory interface
 * @export
 */
export const DiscountsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DiscountsApiFp(configuration)
    return {
        /**
         * 
         * @param {DiscountCreationRequest} discountCreationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discountsCreate(discountCreationRequest: DiscountCreationRequest, options?: any): AxiosPromise<MinimalDiscount> {
            return localVarFp.discountsCreate(discountCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discountsGet(id: string, options?: any): AxiosPromise<Discount> {
            return localVarFp.discountsGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discountsList(options?: any): AxiosPromise<MinimalDiscountSet> {
            return localVarFp.discountsList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {PatchRequest} patchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discountsUpdate(id: string, patchRequest: PatchRequest, options?: any): AxiosPromise<Discount> {
            return localVarFp.discountsUpdate(id, patchRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DiscountsApi - interface
 * @export
 * @interface DiscountsApi
 */
export interface DiscountsApiInterface {
    /**
     * 
     * @param {DiscountCreationRequest} discountCreationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscountsApiInterface
     */
    discountsCreate(discountCreationRequest: DiscountCreationRequest, options?: AxiosRequestConfig): AxiosPromise<MinimalDiscount>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscountsApiInterface
     */
    discountsGet(id: string, options?: AxiosRequestConfig): AxiosPromise<Discount>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscountsApiInterface
     */
    discountsList(options?: AxiosRequestConfig): AxiosPromise<MinimalDiscountSet>;

    /**
     * 
     * @param {string} id 
     * @param {PatchRequest} patchRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscountsApiInterface
     */
    discountsUpdate(id: string, patchRequest: PatchRequest, options?: AxiosRequestConfig): AxiosPromise<Discount>;

}

/**
 * DiscountsApi - object-oriented interface
 * @export
 * @class DiscountsApi
 * @extends {BaseAPI}
 */
export class DiscountsApi extends BaseAPI implements DiscountsApiInterface {
    /**
     * 
     * @param {DiscountCreationRequest} discountCreationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscountsApi
     */
    public discountsCreate(discountCreationRequest: DiscountCreationRequest, options?: AxiosRequestConfig) {
        return DiscountsApiFp(this.configuration).discountsCreate(discountCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscountsApi
     */
    public discountsGet(id: string, options?: AxiosRequestConfig) {
        return DiscountsApiFp(this.configuration).discountsGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscountsApi
     */
    public discountsList(options?: AxiosRequestConfig) {
        return DiscountsApiFp(this.configuration).discountsList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {PatchRequest} patchRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscountsApi
     */
    public discountsUpdate(id: string, patchRequest: PatchRequest, options?: AxiosRequestConfig) {
        return DiscountsApiFp(this.configuration).discountsUpdate(id, patchRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DocsApi - axios parameter creator
 * @export
 */
export const DocsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        docsDocs: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/data/api-docs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DocsApi - functional programming interface
 * @export
 */
export const DocsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DocsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async docsDocs(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.docsDocs(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DocsApi - factory interface
 * @export
 */
export const DocsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DocsApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        docsDocs(options?: any): AxiosPromise<void> {
            return localVarFp.docsDocs(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DocsApi - interface
 * @export
 * @interface DocsApi
 */
export interface DocsApiInterface {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocsApiInterface
     */
    docsDocs(options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * DocsApi - object-oriented interface
 * @export
 * @class DocsApi
 * @extends {BaseAPI}
 */
export class DocsApi extends BaseAPI implements DocsApiInterface {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocsApi
     */
    public docsDocs(options?: AxiosRequestConfig) {
        return DocsApiFp(this.configuration).docsDocs(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GlobalPlansApi - axios parameter creator
 * @export
 */
export const GlobalPlansApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {GlobalPlanCreationRequest} globalPlanCreationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalPlansCreate: async (globalPlanCreationRequest: GlobalPlanCreationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'globalPlanCreationRequest' is not null or undefined
            assertParamExists('globalPlansCreate', 'globalPlanCreationRequest', globalPlanCreationRequest)
            const localVarPath = `/api/v2/data/globalPlans`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(globalPlanCreationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalPlansGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('globalPlansGet', 'id', id)
            const localVarPath = `/api/v2/data/globalPlans/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalPlansList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/data/globalPlans`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} globalPlanId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalPlansListLinks: async (globalPlanId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'globalPlanId' is not null or undefined
            assertParamExists('globalPlansListLinks', 'globalPlanId', globalPlanId)
            const localVarPath = `/api/v2/data/globalPlans/{globalPlanId}/links`
                .replace(`{${"globalPlanId"}}`, encodeURIComponent(String(globalPlanId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} globalPlanId 
         * @param {LinkSetRequest} linkSetRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalPlansSetLinks: async (globalPlanId: number, linkSetRequest: LinkSetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'globalPlanId' is not null or undefined
            assertParamExists('globalPlansSetLinks', 'globalPlanId', globalPlanId)
            // verify required parameter 'linkSetRequest' is not null or undefined
            assertParamExists('globalPlansSetLinks', 'linkSetRequest', linkSetRequest)
            const localVarPath = `/api/v2/data/globalPlans/{globalPlanId}/links`
                .replace(`{${"globalPlanId"}}`, encodeURIComponent(String(globalPlanId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(linkSetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {PatchRequest} patchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalPlansUpdate: async (id: number, patchRequest: PatchRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('globalPlansUpdate', 'id', id)
            // verify required parameter 'patchRequest' is not null or undefined
            assertParamExists('globalPlansUpdate', 'patchRequest', patchRequest)
            const localVarPath = `/api/v2/data/globalPlans/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GlobalPlansApi - functional programming interface
 * @export
 */
export const GlobalPlansApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GlobalPlansApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {GlobalPlanCreationRequest} globalPlanCreationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async globalPlansCreate(globalPlanCreationRequest: GlobalPlanCreationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GlobalPlan>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.globalPlansCreate(globalPlanCreationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async globalPlansGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GlobalPlan>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.globalPlansGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async globalPlansList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MinimalGlobalPlanSet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.globalPlansList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} globalPlanId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async globalPlansListLinks(globalPlanId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LinkSet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.globalPlansListLinks(globalPlanId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} globalPlanId 
         * @param {LinkSetRequest} linkSetRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async globalPlansSetLinks(globalPlanId: number, linkSetRequest: LinkSetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.globalPlansSetLinks(globalPlanId, linkSetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {PatchRequest} patchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async globalPlansUpdate(id: number, patchRequest: PatchRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GlobalPlan>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.globalPlansUpdate(id, patchRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GlobalPlansApi - factory interface
 * @export
 */
export const GlobalPlansApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GlobalPlansApiFp(configuration)
    return {
        /**
         * 
         * @param {GlobalPlanCreationRequest} globalPlanCreationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalPlansCreate(globalPlanCreationRequest: GlobalPlanCreationRequest, options?: any): AxiosPromise<GlobalPlan> {
            return localVarFp.globalPlansCreate(globalPlanCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalPlansGet(id: number, options?: any): AxiosPromise<GlobalPlan> {
            return localVarFp.globalPlansGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalPlansList(options?: any): AxiosPromise<MinimalGlobalPlanSet> {
            return localVarFp.globalPlansList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} globalPlanId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalPlansListLinks(globalPlanId: number, options?: any): AxiosPromise<LinkSet> {
            return localVarFp.globalPlansListLinks(globalPlanId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} globalPlanId 
         * @param {LinkSetRequest} linkSetRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalPlansSetLinks(globalPlanId: number, linkSetRequest: LinkSetRequest, options?: any): AxiosPromise<void> {
            return localVarFp.globalPlansSetLinks(globalPlanId, linkSetRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {PatchRequest} patchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalPlansUpdate(id: number, patchRequest: PatchRequest, options?: any): AxiosPromise<GlobalPlan> {
            return localVarFp.globalPlansUpdate(id, patchRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GlobalPlansApi - interface
 * @export
 * @interface GlobalPlansApi
 */
export interface GlobalPlansApiInterface {
    /**
     * 
     * @param {GlobalPlanCreationRequest} globalPlanCreationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalPlansApiInterface
     */
    globalPlansCreate(globalPlanCreationRequest: GlobalPlanCreationRequest, options?: AxiosRequestConfig): AxiosPromise<GlobalPlan>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalPlansApiInterface
     */
    globalPlansGet(id: number, options?: AxiosRequestConfig): AxiosPromise<GlobalPlan>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalPlansApiInterface
     */
    globalPlansList(options?: AxiosRequestConfig): AxiosPromise<MinimalGlobalPlanSet>;

    /**
     * 
     * @param {number} globalPlanId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalPlansApiInterface
     */
    globalPlansListLinks(globalPlanId: number, options?: AxiosRequestConfig): AxiosPromise<LinkSet>;

    /**
     * 
     * @param {number} globalPlanId 
     * @param {LinkSetRequest} linkSetRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalPlansApiInterface
     */
    globalPlansSetLinks(globalPlanId: number, linkSetRequest: LinkSetRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {number} id 
     * @param {PatchRequest} patchRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalPlansApiInterface
     */
    globalPlansUpdate(id: number, patchRequest: PatchRequest, options?: AxiosRequestConfig): AxiosPromise<GlobalPlan>;

}

/**
 * GlobalPlansApi - object-oriented interface
 * @export
 * @class GlobalPlansApi
 * @extends {BaseAPI}
 */
export class GlobalPlansApi extends BaseAPI implements GlobalPlansApiInterface {
    /**
     * 
     * @param {GlobalPlanCreationRequest} globalPlanCreationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalPlansApi
     */
    public globalPlansCreate(globalPlanCreationRequest: GlobalPlanCreationRequest, options?: AxiosRequestConfig) {
        return GlobalPlansApiFp(this.configuration).globalPlansCreate(globalPlanCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalPlansApi
     */
    public globalPlansGet(id: number, options?: AxiosRequestConfig) {
        return GlobalPlansApiFp(this.configuration).globalPlansGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalPlansApi
     */
    public globalPlansList(options?: AxiosRequestConfig) {
        return GlobalPlansApiFp(this.configuration).globalPlansList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} globalPlanId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalPlansApi
     */
    public globalPlansListLinks(globalPlanId: number, options?: AxiosRequestConfig) {
        return GlobalPlansApiFp(this.configuration).globalPlansListLinks(globalPlanId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} globalPlanId 
     * @param {LinkSetRequest} linkSetRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalPlansApi
     */
    public globalPlansSetLinks(globalPlanId: number, linkSetRequest: LinkSetRequest, options?: AxiosRequestConfig) {
        return GlobalPlansApiFp(this.configuration).globalPlansSetLinks(globalPlanId, linkSetRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {PatchRequest} patchRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalPlansApi
     */
    public globalPlansUpdate(id: number, patchRequest: PatchRequest, options?: AxiosRequestConfig) {
        return GlobalPlansApiFp(this.configuration).globalPlansUpdate(id, patchRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * HealthApi - axios parameter creator
 * @export
 */
export const HealthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthReady: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/data/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HealthApi - functional programming interface
 * @export
 */
export const HealthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HealthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthReady(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthReady(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HealthApi - factory interface
 * @export
 */
export const HealthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HealthApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthReady(options?: any): AxiosPromise<void> {
            return localVarFp.healthReady(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HealthApi - interface
 * @export
 * @interface HealthApi
 */
export interface HealthApiInterface {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthApiInterface
     */
    healthReady(options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * HealthApi - object-oriented interface
 * @export
 * @class HealthApi
 * @extends {BaseAPI}
 */
export class HealthApi extends BaseAPI implements HealthApiInterface {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthApi
     */
    public healthReady(options?: AxiosRequestConfig) {
        return HealthApiFp(this.configuration).healthReady(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InventoryCategoriesApi - axios parameter creator
 * @export
 */
export const InventoryCategoriesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {InventoryCategoryCreationRequest} inventoryCategoryCreationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryCategoriesCreate: async (inventoryCategoryCreationRequest: InventoryCategoryCreationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'inventoryCategoryCreationRequest' is not null or undefined
            assertParamExists('inventoryCategoriesCreate', 'inventoryCategoryCreationRequest', inventoryCategoryCreationRequest)
            const localVarPath = `/api/v2/data/inventoryCategories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inventoryCategoryCreationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryCategoriesGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('inventoryCategoriesGet', 'id', id)
            const localVarPath = `/api/v2/data/inventoryCategories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryCategoriesList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/data/inventoryCategories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {PatchRequest} patchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryCategoriesUpdate: async (id: number, patchRequest: PatchRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('inventoryCategoriesUpdate', 'id', id)
            // verify required parameter 'patchRequest' is not null or undefined
            assertParamExists('inventoryCategoriesUpdate', 'patchRequest', patchRequest)
            const localVarPath = `/api/v2/data/inventoryCategories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InventoryCategoriesApi - functional programming interface
 * @export
 */
export const InventoryCategoriesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InventoryCategoriesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {InventoryCategoryCreationRequest} inventoryCategoryCreationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inventoryCategoriesCreate(inventoryCategoryCreationRequest: InventoryCategoryCreationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InventoryCategory>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inventoryCategoriesCreate(inventoryCategoryCreationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inventoryCategoriesGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InventoryCategory>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inventoryCategoriesGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inventoryCategoriesList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MinimalInventoryCategorySet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inventoryCategoriesList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {PatchRequest} patchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inventoryCategoriesUpdate(id: number, patchRequest: PatchRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InventoryCategory>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inventoryCategoriesUpdate(id, patchRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InventoryCategoriesApi - factory interface
 * @export
 */
export const InventoryCategoriesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InventoryCategoriesApiFp(configuration)
    return {
        /**
         * 
         * @param {InventoryCategoryCreationRequest} inventoryCategoryCreationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryCategoriesCreate(inventoryCategoryCreationRequest: InventoryCategoryCreationRequest, options?: any): AxiosPromise<InventoryCategory> {
            return localVarFp.inventoryCategoriesCreate(inventoryCategoryCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryCategoriesGet(id: number, options?: any): AxiosPromise<InventoryCategory> {
            return localVarFp.inventoryCategoriesGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryCategoriesList(options?: any): AxiosPromise<MinimalInventoryCategorySet> {
            return localVarFp.inventoryCategoriesList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {PatchRequest} patchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryCategoriesUpdate(id: number, patchRequest: PatchRequest, options?: any): AxiosPromise<InventoryCategory> {
            return localVarFp.inventoryCategoriesUpdate(id, patchRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InventoryCategoriesApi - interface
 * @export
 * @interface InventoryCategoriesApi
 */
export interface InventoryCategoriesApiInterface {
    /**
     * 
     * @param {InventoryCategoryCreationRequest} inventoryCategoryCreationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryCategoriesApiInterface
     */
    inventoryCategoriesCreate(inventoryCategoryCreationRequest: InventoryCategoryCreationRequest, options?: AxiosRequestConfig): AxiosPromise<InventoryCategory>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryCategoriesApiInterface
     */
    inventoryCategoriesGet(id: number, options?: AxiosRequestConfig): AxiosPromise<InventoryCategory>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryCategoriesApiInterface
     */
    inventoryCategoriesList(options?: AxiosRequestConfig): AxiosPromise<MinimalInventoryCategorySet>;

    /**
     * 
     * @param {number} id 
     * @param {PatchRequest} patchRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryCategoriesApiInterface
     */
    inventoryCategoriesUpdate(id: number, patchRequest: PatchRequest, options?: AxiosRequestConfig): AxiosPromise<InventoryCategory>;

}

/**
 * InventoryCategoriesApi - object-oriented interface
 * @export
 * @class InventoryCategoriesApi
 * @extends {BaseAPI}
 */
export class InventoryCategoriesApi extends BaseAPI implements InventoryCategoriesApiInterface {
    /**
     * 
     * @param {InventoryCategoryCreationRequest} inventoryCategoryCreationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryCategoriesApi
     */
    public inventoryCategoriesCreate(inventoryCategoryCreationRequest: InventoryCategoryCreationRequest, options?: AxiosRequestConfig) {
        return InventoryCategoriesApiFp(this.configuration).inventoryCategoriesCreate(inventoryCategoryCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryCategoriesApi
     */
    public inventoryCategoriesGet(id: number, options?: AxiosRequestConfig) {
        return InventoryCategoriesApiFp(this.configuration).inventoryCategoriesGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryCategoriesApi
     */
    public inventoryCategoriesList(options?: AxiosRequestConfig) {
        return InventoryCategoriesApiFp(this.configuration).inventoryCategoriesList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {PatchRequest} patchRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryCategoriesApi
     */
    public inventoryCategoriesUpdate(id: number, patchRequest: PatchRequest, options?: AxiosRequestConfig) {
        return InventoryCategoriesApiFp(this.configuration).inventoryCategoriesUpdate(id, patchRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrdersApi - axios parameter creator
 * @export
 */
export const OrdersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {OrderRequest} orderRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersCreate: async (orderRequest: OrderRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderRequest' is not null or undefined
            assertParamExists('ordersCreate', 'orderRequest', orderRequest)
            const localVarPath = `/api/v2/data/orders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(orderRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {OrderPlanRequest} orderPlanRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersPlan: async (orderPlanRequest: OrderPlanRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderPlanRequest' is not null or undefined
            assertParamExists('ordersPlan', 'orderPlanRequest', orderPlanRequest)
            const localVarPath = `/api/v2/data/orders/plan`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(orderPlanRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrdersApi - functional programming interface
 * @export
 */
export const OrdersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrdersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {OrderRequest} orderRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordersCreate(orderRequest: OrderRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Order>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ordersCreate(orderRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {OrderPlanRequest} orderPlanRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordersPlan(orderPlanRequest: OrderPlanRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderPlan>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ordersPlan(orderPlanRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrdersApi - factory interface
 * @export
 */
export const OrdersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrdersApiFp(configuration)
    return {
        /**
         * 
         * @param {OrderRequest} orderRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersCreate(orderRequest: OrderRequest, options?: any): AxiosPromise<Order> {
            return localVarFp.ordersCreate(orderRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OrderPlanRequest} orderPlanRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersPlan(orderPlanRequest: OrderPlanRequest, options?: any): AxiosPromise<OrderPlan> {
            return localVarFp.ordersPlan(orderPlanRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrdersApi - interface
 * @export
 * @interface OrdersApi
 */
export interface OrdersApiInterface {
    /**
     * 
     * @param {OrderRequest} orderRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApiInterface
     */
    ordersCreate(orderRequest: OrderRequest, options?: AxiosRequestConfig): AxiosPromise<Order>;

    /**
     * 
     * @param {OrderPlanRequest} orderPlanRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApiInterface
     */
    ordersPlan(orderPlanRequest: OrderPlanRequest, options?: AxiosRequestConfig): AxiosPromise<OrderPlan>;

}

/**
 * OrdersApi - object-oriented interface
 * @export
 * @class OrdersApi
 * @extends {BaseAPI}
 */
export class OrdersApi extends BaseAPI implements OrdersApiInterface {
    /**
     * 
     * @param {OrderRequest} orderRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public ordersCreate(orderRequest: OrderRequest, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).ordersCreate(orderRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OrderPlanRequest} orderPlanRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public ordersPlan(orderPlanRequest: OrderPlanRequest, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).ordersPlan(orderPlanRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PaymentPlansApi - axios parameter creator
 * @export
 */
export const PaymentPlansApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {PaymentPlanCreationRequest} paymentPlanCreationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentPlansCreate: async (paymentPlanCreationRequest: PaymentPlanCreationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'paymentPlanCreationRequest' is not null or undefined
            assertParamExists('paymentPlansCreate', 'paymentPlanCreationRequest', paymentPlanCreationRequest)
            const localVarPath = `/api/v2/data/paymentPlans`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paymentPlanCreationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentPlansGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('paymentPlansGet', 'id', id)
            const localVarPath = `/api/v2/data/paymentPlans/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentPlansList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/data/paymentPlans`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentPlansListStores: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('paymentPlansListStores', 'id', id)
            const localVarPath = `/api/v2/data/paymentPlans/{id}/stores`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {PaymentPlanDateReplacementRequest} paymentPlanDateReplacementRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentPlansSetDateList: async (id: number, paymentPlanDateReplacementRequest: PaymentPlanDateReplacementRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('paymentPlansSetDateList', 'id', id)
            // verify required parameter 'paymentPlanDateReplacementRequest' is not null or undefined
            assertParamExists('paymentPlansSetDateList', 'paymentPlanDateReplacementRequest', paymentPlanDateReplacementRequest)
            const localVarPath = `/api/v2/data/paymentPlans/{id}/dates`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paymentPlanDateReplacementRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {PaymentPlanStoreReplacementRequest} paymentPlanStoreReplacementRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentPlansSetStoreList: async (id: number, paymentPlanStoreReplacementRequest: PaymentPlanStoreReplacementRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('paymentPlansSetStoreList', 'id', id)
            // verify required parameter 'paymentPlanStoreReplacementRequest' is not null or undefined
            assertParamExists('paymentPlansSetStoreList', 'paymentPlanStoreReplacementRequest', paymentPlanStoreReplacementRequest)
            const localVarPath = `/api/v2/data/paymentPlans/{id}/stores`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paymentPlanStoreReplacementRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {PatchRequest} patchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentPlansUpdate: async (id: number, patchRequest: PatchRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('paymentPlansUpdate', 'id', id)
            // verify required parameter 'patchRequest' is not null or undefined
            assertParamExists('paymentPlansUpdate', 'patchRequest', patchRequest)
            const localVarPath = `/api/v2/data/paymentPlans/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PaymentPlansApi - functional programming interface
 * @export
 */
export const PaymentPlansApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PaymentPlansApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {PaymentPlanCreationRequest} paymentPlanCreationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentPlansCreate(paymentPlanCreationRequest: PaymentPlanCreationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MinimalPaymentPlan>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentPlansCreate(paymentPlanCreationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentPlansGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentPlan>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentPlansGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentPlansList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MinimalPaymentPlanSet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentPlansList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentPlansListStores(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentPlanStoreResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentPlansListStores(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {PaymentPlanDateReplacementRequest} paymentPlanDateReplacementRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentPlansSetDateList(id: number, paymentPlanDateReplacementRequest: PaymentPlanDateReplacementRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentPlan>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentPlansSetDateList(id, paymentPlanDateReplacementRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {PaymentPlanStoreReplacementRequest} paymentPlanStoreReplacementRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentPlansSetStoreList(id: number, paymentPlanStoreReplacementRequest: PaymentPlanStoreReplacementRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentPlan>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentPlansSetStoreList(id, paymentPlanStoreReplacementRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {PatchRequest} patchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentPlansUpdate(id: number, patchRequest: PatchRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentPlan>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentPlansUpdate(id, patchRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PaymentPlansApi - factory interface
 * @export
 */
export const PaymentPlansApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PaymentPlansApiFp(configuration)
    return {
        /**
         * 
         * @param {PaymentPlanCreationRequest} paymentPlanCreationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentPlansCreate(paymentPlanCreationRequest: PaymentPlanCreationRequest, options?: any): AxiosPromise<MinimalPaymentPlan> {
            return localVarFp.paymentPlansCreate(paymentPlanCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentPlansGet(id: number, options?: any): AxiosPromise<PaymentPlan> {
            return localVarFp.paymentPlansGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentPlansList(options?: any): AxiosPromise<MinimalPaymentPlanSet> {
            return localVarFp.paymentPlansList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentPlansListStores(id: number, options?: any): AxiosPromise<PaymentPlanStoreResponse> {
            return localVarFp.paymentPlansListStores(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {PaymentPlanDateReplacementRequest} paymentPlanDateReplacementRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentPlansSetDateList(id: number, paymentPlanDateReplacementRequest: PaymentPlanDateReplacementRequest, options?: any): AxiosPromise<PaymentPlan> {
            return localVarFp.paymentPlansSetDateList(id, paymentPlanDateReplacementRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {PaymentPlanStoreReplacementRequest} paymentPlanStoreReplacementRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentPlansSetStoreList(id: number, paymentPlanStoreReplacementRequest: PaymentPlanStoreReplacementRequest, options?: any): AxiosPromise<PaymentPlan> {
            return localVarFp.paymentPlansSetStoreList(id, paymentPlanStoreReplacementRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {PatchRequest} patchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentPlansUpdate(id: number, patchRequest: PatchRequest, options?: any): AxiosPromise<PaymentPlan> {
            return localVarFp.paymentPlansUpdate(id, patchRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PaymentPlansApi - interface
 * @export
 * @interface PaymentPlansApi
 */
export interface PaymentPlansApiInterface {
    /**
     * 
     * @param {PaymentPlanCreationRequest} paymentPlanCreationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentPlansApiInterface
     */
    paymentPlansCreate(paymentPlanCreationRequest: PaymentPlanCreationRequest, options?: AxiosRequestConfig): AxiosPromise<MinimalPaymentPlan>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentPlansApiInterface
     */
    paymentPlansGet(id: number, options?: AxiosRequestConfig): AxiosPromise<PaymentPlan>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentPlansApiInterface
     */
    paymentPlansList(options?: AxiosRequestConfig): AxiosPromise<MinimalPaymentPlanSet>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentPlansApiInterface
     */
    paymentPlansListStores(id: number, options?: AxiosRequestConfig): AxiosPromise<PaymentPlanStoreResponse>;

    /**
     * 
     * @param {number} id 
     * @param {PaymentPlanDateReplacementRequest} paymentPlanDateReplacementRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentPlansApiInterface
     */
    paymentPlansSetDateList(id: number, paymentPlanDateReplacementRequest: PaymentPlanDateReplacementRequest, options?: AxiosRequestConfig): AxiosPromise<PaymentPlan>;

    /**
     * 
     * @param {number} id 
     * @param {PaymentPlanStoreReplacementRequest} paymentPlanStoreReplacementRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentPlansApiInterface
     */
    paymentPlansSetStoreList(id: number, paymentPlanStoreReplacementRequest: PaymentPlanStoreReplacementRequest, options?: AxiosRequestConfig): AxiosPromise<PaymentPlan>;

    /**
     * 
     * @param {number} id 
     * @param {PatchRequest} patchRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentPlansApiInterface
     */
    paymentPlansUpdate(id: number, patchRequest: PatchRequest, options?: AxiosRequestConfig): AxiosPromise<PaymentPlan>;

}

/**
 * PaymentPlansApi - object-oriented interface
 * @export
 * @class PaymentPlansApi
 * @extends {BaseAPI}
 */
export class PaymentPlansApi extends BaseAPI implements PaymentPlansApiInterface {
    /**
     * 
     * @param {PaymentPlanCreationRequest} paymentPlanCreationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentPlansApi
     */
    public paymentPlansCreate(paymentPlanCreationRequest: PaymentPlanCreationRequest, options?: AxiosRequestConfig) {
        return PaymentPlansApiFp(this.configuration).paymentPlansCreate(paymentPlanCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentPlansApi
     */
    public paymentPlansGet(id: number, options?: AxiosRequestConfig) {
        return PaymentPlansApiFp(this.configuration).paymentPlansGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentPlansApi
     */
    public paymentPlansList(options?: AxiosRequestConfig) {
        return PaymentPlansApiFp(this.configuration).paymentPlansList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentPlansApi
     */
    public paymentPlansListStores(id: number, options?: AxiosRequestConfig) {
        return PaymentPlansApiFp(this.configuration).paymentPlansListStores(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {PaymentPlanDateReplacementRequest} paymentPlanDateReplacementRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentPlansApi
     */
    public paymentPlansSetDateList(id: number, paymentPlanDateReplacementRequest: PaymentPlanDateReplacementRequest, options?: AxiosRequestConfig) {
        return PaymentPlansApiFp(this.configuration).paymentPlansSetDateList(id, paymentPlanDateReplacementRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {PaymentPlanStoreReplacementRequest} paymentPlanStoreReplacementRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentPlansApi
     */
    public paymentPlansSetStoreList(id: number, paymentPlanStoreReplacementRequest: PaymentPlanStoreReplacementRequest, options?: AxiosRequestConfig) {
        return PaymentPlansApiFp(this.configuration).paymentPlansSetStoreList(id, paymentPlanStoreReplacementRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {PatchRequest} patchRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentPlansApi
     */
    public paymentPlansUpdate(id: number, patchRequest: PatchRequest, options?: AxiosRequestConfig) {
        return PaymentPlansApiFp(this.configuration).paymentPlansUpdate(id, patchRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StoresApi - axios parameter creator
 * @export
 */
export const StoresApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} storeId 
         * @param {number} planId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storesArchivePlan: async (storeId: number, planId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('storesArchivePlan', 'storeId', storeId)
            // verify required parameter 'planId' is not null or undefined
            assertParamExists('storesArchivePlan', 'planId', planId)
            const localVarPath = `/api/v2/data/stores/{storeId}/plans/{planId}`
                .replace(`{${"storeId"}}`, encodeURIComponent(String(storeId)))
                .replace(`{${"planId"}}`, encodeURIComponent(String(planId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateStoreRequest} createStoreRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storesCreate: async (createStoreRequest: CreateStoreRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createStoreRequest' is not null or undefined
            assertParamExists('storesCreate', 'createStoreRequest', createStoreRequest)
            const localVarPath = `/api/v2/data/stores`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createStoreRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {CreateLocationRequest} createLocationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storesCreateLocation: async (id: number, createLocationRequest: CreateLocationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('storesCreateLocation', 'id', id)
            // verify required parameter 'createLocationRequest' is not null or undefined
            assertParamExists('storesCreateLocation', 'createLocationRequest', createLocationRequest)
            const localVarPath = `/api/v2/data/stores/{id}/locations`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createLocationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} storeId 
         * @param {CreatePlanRequest} createPlanRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storesCreatePlan: async (storeId: number, createPlanRequest: CreatePlanRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('storesCreatePlan', 'storeId', storeId)
            // verify required parameter 'createPlanRequest' is not null or undefined
            assertParamExists('storesCreatePlan', 'createPlanRequest', createPlanRequest)
            const localVarPath = `/api/v2/data/stores/{storeId}/plans`
                .replace(`{${"storeId"}}`, encodeURIComponent(String(storeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPlanRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} storeId 
         * @param {number} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storesDeleteLocation: async (storeId: number, locationId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('storesDeleteLocation', 'storeId', storeId)
            // verify required parameter 'locationId' is not null or undefined
            assertParamExists('storesDeleteLocation', 'locationId', locationId)
            const localVarPath = `/api/v2/data/stores/{storeId}/locations/{locationId}`
                .replace(`{${"storeId"}}`, encodeURIComponent(String(storeId)))
                .replace(`{${"locationId"}}`, encodeURIComponent(String(locationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storesGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('storesGet', 'id', id)
            const localVarPath = `/api/v2/data/stores/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} storeId 
         * @param {number} planId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storesGetPlan: async (storeId: number, planId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('storesGetPlan', 'storeId', storeId)
            // verify required parameter 'planId' is not null or undefined
            assertParamExists('storesGetPlan', 'planId', planId)
            const localVarPath = `/api/v2/data/stores/{storeId}/plans/{planId}`
                .replace(`{${"storeId"}}`, encodeURIComponent(String(storeId)))
                .replace(`{${"planId"}}`, encodeURIComponent(String(planId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} storeId 
         * @param {number} planId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storesGetPlanAddons: async (storeId: number, planId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('storesGetPlanAddons', 'storeId', storeId)
            // verify required parameter 'planId' is not null or undefined
            assertParamExists('storesGetPlanAddons', 'planId', planId)
            const localVarPath = `/api/v2/data/stores/{storeId}/plans/{planId}/addons`
                .replace(`{${"storeId"}}`, encodeURIComponent(String(storeId)))
                .replace(`{${"planId"}}`, encodeURIComponent(String(planId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} storeId 
         * @param {number} planId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storesGetRentalCounts: async (storeId: number, planId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('storesGetRentalCounts', 'storeId', storeId)
            // verify required parameter 'planId' is not null or undefined
            assertParamExists('storesGetRentalCounts', 'planId', planId)
            const localVarPath = `/api/v2/data/stores/{storeId}/plans/{planId}/rentals`
                .replace(`{${"storeId"}}`, encodeURIComponent(String(storeId)))
                .replace(`{${"planId"}}`, encodeURIComponent(String(planId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storesList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/data/stores`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storesListLocations: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('storesListLocations', 'id', id)
            const localVarPath = `/api/v2/data/stores/{id}/locations`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storesListPlans: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('storesListPlans', 'id', id)
            const localVarPath = `/api/v2/data/stores/{id}/plans`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storesListProrations: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('storesListProrations', 'id', id)
            const localVarPath = `/api/v2/data/stores/{id}/prorations`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} storeId 
         * @param {number} planId 
         * @param {StorePlanAddonChangeRequestSet} storePlanAddonChangeRequestSet 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storesOverwritePlanAddons: async (storeId: number, planId: number, storePlanAddonChangeRequestSet: StorePlanAddonChangeRequestSet, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('storesOverwritePlanAddons', 'storeId', storeId)
            // verify required parameter 'planId' is not null or undefined
            assertParamExists('storesOverwritePlanAddons', 'planId', planId)
            // verify required parameter 'storePlanAddonChangeRequestSet' is not null or undefined
            assertParamExists('storesOverwritePlanAddons', 'storePlanAddonChangeRequestSet', storePlanAddonChangeRequestSet)
            const localVarPath = `/api/v2/data/stores/{storeId}/plans/{planId}/addons`
                .replace(`{${"storeId"}}`, encodeURIComponent(String(storeId)))
                .replace(`{${"planId"}}`, encodeURIComponent(String(planId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(storePlanAddonChangeRequestSet, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {NewProrationSet} newProrationSet 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storesOverwriteProrations: async (id: number, newProrationSet: NewProrationSet, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('storesOverwriteProrations', 'id', id)
            // verify required parameter 'newProrationSet' is not null or undefined
            assertParamExists('storesOverwriteProrations', 'newProrationSet', newProrationSet)
            const localVarPath = `/api/v2/data/stores/{id}/prorations`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newProrationSet, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} storeId 
         * @param {number} planId 
         * @param {RentalCountSet} rentalCountSet 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storesOverwriteRentalCounts: async (storeId: number, planId: number, rentalCountSet: RentalCountSet, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('storesOverwriteRentalCounts', 'storeId', storeId)
            // verify required parameter 'planId' is not null or undefined
            assertParamExists('storesOverwriteRentalCounts', 'planId', planId)
            // verify required parameter 'rentalCountSet' is not null or undefined
            assertParamExists('storesOverwriteRentalCounts', 'rentalCountSet', rentalCountSet)
            const localVarPath = `/api/v2/data/stores/{storeId}/plans/{planId}/rentals`
                .replace(`{${"storeId"}}`, encodeURIComponent(String(storeId)))
                .replace(`{${"planId"}}`, encodeURIComponent(String(planId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rentalCountSet, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {PatchRequest} patchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storesUpdate: async (id: number, patchRequest: PatchRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('storesUpdate', 'id', id)
            // verify required parameter 'patchRequest' is not null or undefined
            assertParamExists('storesUpdate', 'patchRequest', patchRequest)
            const localVarPath = `/api/v2/data/stores/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} storeId 
         * @param {number} planId 
         * @param {PatchRequest} patchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storesUpdatePlan: async (storeId: number, planId: number, patchRequest: PatchRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('storesUpdatePlan', 'storeId', storeId)
            // verify required parameter 'planId' is not null or undefined
            assertParamExists('storesUpdatePlan', 'planId', planId)
            // verify required parameter 'patchRequest' is not null or undefined
            assertParamExists('storesUpdatePlan', 'patchRequest', patchRequest)
            const localVarPath = `/api/v2/data/stores/{storeId}/plans/{planId}`
                .replace(`{${"storeId"}}`, encodeURIComponent(String(storeId)))
                .replace(`{${"planId"}}`, encodeURIComponent(String(planId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<Location>} location 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storesUpdateRouteStops: async (id: number, location: Array<Location>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('storesUpdateRouteStops', 'id', id)
            // verify required parameter 'location' is not null or undefined
            assertParamExists('storesUpdateRouteStops', 'location', location)
            const localVarPath = `/api/v2/data/stores/{id}/locations/rtStop`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(location, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StoresApi - functional programming interface
 * @export
 */
export const StoresApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StoresApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} storeId 
         * @param {number} planId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storesArchivePlan(storeId: number, planId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storesArchivePlan(storeId, planId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateStoreRequest} createStoreRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storesCreate(createStoreRequest: CreateStoreRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Store>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storesCreate(createStoreRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {CreateLocationRequest} createLocationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storesCreateLocation(id: number, createLocationRequest: CreateLocationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Location>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storesCreateLocation(id, createLocationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} storeId 
         * @param {CreatePlanRequest} createPlanRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storesCreatePlan(storeId: number, createPlanRequest: CreatePlanRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StorePlan>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storesCreatePlan(storeId, createPlanRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} storeId 
         * @param {number} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storesDeleteLocation(storeId: number, locationId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storesDeleteLocation(storeId, locationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storesGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Store>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storesGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} storeId 
         * @param {number} planId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storesGetPlan(storeId: number, planId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StorePlan>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storesGetPlan(storeId, planId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} storeId 
         * @param {number} planId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storesGetPlanAddons(storeId: number, planId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StorePlanAddonSet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storesGetPlanAddons(storeId, planId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} storeId 
         * @param {number} planId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storesGetRentalCounts(storeId: number, planId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RentalCountSet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storesGetRentalCounts(storeId, planId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storesList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MinimalStoreSet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storesList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storesListLocations(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocationSet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storesListLocations(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storesListPlans(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MinimalStorePlanSet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storesListPlans(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storesListProrations(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProrationSet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storesListProrations(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} storeId 
         * @param {number} planId 
         * @param {StorePlanAddonChangeRequestSet} storePlanAddonChangeRequestSet 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storesOverwritePlanAddons(storeId: number, planId: number, storePlanAddonChangeRequestSet: StorePlanAddonChangeRequestSet, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storesOverwritePlanAddons(storeId, planId, storePlanAddonChangeRequestSet, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {NewProrationSet} newProrationSet 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storesOverwriteProrations(id: number, newProrationSet: NewProrationSet, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storesOverwriteProrations(id, newProrationSet, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} storeId 
         * @param {number} planId 
         * @param {RentalCountSet} rentalCountSet 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storesOverwriteRentalCounts(storeId: number, planId: number, rentalCountSet: RentalCountSet, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storesOverwriteRentalCounts(storeId, planId, rentalCountSet, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {PatchRequest} patchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storesUpdate(id: number, patchRequest: PatchRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Store>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storesUpdate(id, patchRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} storeId 
         * @param {number} planId 
         * @param {PatchRequest} patchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storesUpdatePlan(storeId: number, planId: number, patchRequest: PatchRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StorePlan>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storesUpdatePlan(storeId, planId, patchRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<Location>} location 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storesUpdateRouteStops(id: number, location: Array<Location>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storesUpdateRouteStops(id, location, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StoresApi - factory interface
 * @export
 */
export const StoresApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StoresApiFp(configuration)
    return {
        /**
         * 
         * @param {number} storeId 
         * @param {number} planId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storesArchivePlan(storeId: number, planId: number, options?: any): AxiosPromise<void> {
            return localVarFp.storesArchivePlan(storeId, planId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateStoreRequest} createStoreRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storesCreate(createStoreRequest: CreateStoreRequest, options?: any): AxiosPromise<Store> {
            return localVarFp.storesCreate(createStoreRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {CreateLocationRequest} createLocationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storesCreateLocation(id: number, createLocationRequest: CreateLocationRequest, options?: any): AxiosPromise<Location> {
            return localVarFp.storesCreateLocation(id, createLocationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} storeId 
         * @param {CreatePlanRequest} createPlanRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storesCreatePlan(storeId: number, createPlanRequest: CreatePlanRequest, options?: any): AxiosPromise<StorePlan> {
            return localVarFp.storesCreatePlan(storeId, createPlanRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} storeId 
         * @param {number} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storesDeleteLocation(storeId: number, locationId: number, options?: any): AxiosPromise<void> {
            return localVarFp.storesDeleteLocation(storeId, locationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storesGet(id: number, options?: any): AxiosPromise<Store> {
            return localVarFp.storesGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} storeId 
         * @param {number} planId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storesGetPlan(storeId: number, planId: number, options?: any): AxiosPromise<StorePlan> {
            return localVarFp.storesGetPlan(storeId, planId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} storeId 
         * @param {number} planId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storesGetPlanAddons(storeId: number, planId: number, options?: any): AxiosPromise<StorePlanAddonSet> {
            return localVarFp.storesGetPlanAddons(storeId, planId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} storeId 
         * @param {number} planId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storesGetRentalCounts(storeId: number, planId: number, options?: any): AxiosPromise<RentalCountSet> {
            return localVarFp.storesGetRentalCounts(storeId, planId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storesList(options?: any): AxiosPromise<MinimalStoreSet> {
            return localVarFp.storesList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storesListLocations(id: number, options?: any): AxiosPromise<LocationSet> {
            return localVarFp.storesListLocations(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storesListPlans(id: number, options?: any): AxiosPromise<MinimalStorePlanSet> {
            return localVarFp.storesListPlans(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storesListProrations(id: number, options?: any): AxiosPromise<ProrationSet> {
            return localVarFp.storesListProrations(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} storeId 
         * @param {number} planId 
         * @param {StorePlanAddonChangeRequestSet} storePlanAddonChangeRequestSet 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storesOverwritePlanAddons(storeId: number, planId: number, storePlanAddonChangeRequestSet: StorePlanAddonChangeRequestSet, options?: any): AxiosPromise<void> {
            return localVarFp.storesOverwritePlanAddons(storeId, planId, storePlanAddonChangeRequestSet, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {NewProrationSet} newProrationSet 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storesOverwriteProrations(id: number, newProrationSet: NewProrationSet, options?: any): AxiosPromise<void> {
            return localVarFp.storesOverwriteProrations(id, newProrationSet, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} storeId 
         * @param {number} planId 
         * @param {RentalCountSet} rentalCountSet 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storesOverwriteRentalCounts(storeId: number, planId: number, rentalCountSet: RentalCountSet, options?: any): AxiosPromise<void> {
            return localVarFp.storesOverwriteRentalCounts(storeId, planId, rentalCountSet, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {PatchRequest} patchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storesUpdate(id: number, patchRequest: PatchRequest, options?: any): AxiosPromise<Store> {
            return localVarFp.storesUpdate(id, patchRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} storeId 
         * @param {number} planId 
         * @param {PatchRequest} patchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storesUpdatePlan(storeId: number, planId: number, patchRequest: PatchRequest, options?: any): AxiosPromise<StorePlan> {
            return localVarFp.storesUpdatePlan(storeId, planId, patchRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<Location>} location 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storesUpdateRouteStops(id: number, location: Array<Location>, options?: any): AxiosPromise<void> {
            return localVarFp.storesUpdateRouteStops(id, location, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StoresApi - interface
 * @export
 * @interface StoresApi
 */
export interface StoresApiInterface {
    /**
     * 
     * @param {number} storeId 
     * @param {number} planId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoresApiInterface
     */
    storesArchivePlan(storeId: number, planId: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {CreateStoreRequest} createStoreRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoresApiInterface
     */
    storesCreate(createStoreRequest: CreateStoreRequest, options?: AxiosRequestConfig): AxiosPromise<Store>;

    /**
     * 
     * @param {number} id 
     * @param {CreateLocationRequest} createLocationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoresApiInterface
     */
    storesCreateLocation(id: number, createLocationRequest: CreateLocationRequest, options?: AxiosRequestConfig): AxiosPromise<Location>;

    /**
     * 
     * @param {number} storeId 
     * @param {CreatePlanRequest} createPlanRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoresApiInterface
     */
    storesCreatePlan(storeId: number, createPlanRequest: CreatePlanRequest, options?: AxiosRequestConfig): AxiosPromise<StorePlan>;

    /**
     * 
     * @param {number} storeId 
     * @param {number} locationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoresApiInterface
     */
    storesDeleteLocation(storeId: number, locationId: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoresApiInterface
     */
    storesGet(id: number, options?: AxiosRequestConfig): AxiosPromise<Store>;

    /**
     * 
     * @param {number} storeId 
     * @param {number} planId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoresApiInterface
     */
    storesGetPlan(storeId: number, planId: number, options?: AxiosRequestConfig): AxiosPromise<StorePlan>;

    /**
     * 
     * @param {number} storeId 
     * @param {number} planId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoresApiInterface
     */
    storesGetPlanAddons(storeId: number, planId: number, options?: AxiosRequestConfig): AxiosPromise<StorePlanAddonSet>;

    /**
     * 
     * @param {number} storeId 
     * @param {number} planId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoresApiInterface
     */
    storesGetRentalCounts(storeId: number, planId: number, options?: AxiosRequestConfig): AxiosPromise<RentalCountSet>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoresApiInterface
     */
    storesList(options?: AxiosRequestConfig): AxiosPromise<MinimalStoreSet>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoresApiInterface
     */
    storesListLocations(id: number, options?: AxiosRequestConfig): AxiosPromise<LocationSet>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoresApiInterface
     */
    storesListPlans(id: number, options?: AxiosRequestConfig): AxiosPromise<MinimalStorePlanSet>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoresApiInterface
     */
    storesListProrations(id: number, options?: AxiosRequestConfig): AxiosPromise<ProrationSet>;

    /**
     * 
     * @param {number} storeId 
     * @param {number} planId 
     * @param {StorePlanAddonChangeRequestSet} storePlanAddonChangeRequestSet 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoresApiInterface
     */
    storesOverwritePlanAddons(storeId: number, planId: number, storePlanAddonChangeRequestSet: StorePlanAddonChangeRequestSet, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {number} id 
     * @param {NewProrationSet} newProrationSet 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoresApiInterface
     */
    storesOverwriteProrations(id: number, newProrationSet: NewProrationSet, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {number} storeId 
     * @param {number} planId 
     * @param {RentalCountSet} rentalCountSet 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoresApiInterface
     */
    storesOverwriteRentalCounts(storeId: number, planId: number, rentalCountSet: RentalCountSet, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {number} id 
     * @param {PatchRequest} patchRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoresApiInterface
     */
    storesUpdate(id: number, patchRequest: PatchRequest, options?: AxiosRequestConfig): AxiosPromise<Store>;

    /**
     * 
     * @param {number} storeId 
     * @param {number} planId 
     * @param {PatchRequest} patchRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoresApiInterface
     */
    storesUpdatePlan(storeId: number, planId: number, patchRequest: PatchRequest, options?: AxiosRequestConfig): AxiosPromise<StorePlan>;

    /**
     * 
     * @param {number} id 
     * @param {Array<Location>} location 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoresApiInterface
     */
    storesUpdateRouteStops(id: number, location: Array<Location>, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * StoresApi - object-oriented interface
 * @export
 * @class StoresApi
 * @extends {BaseAPI}
 */
export class StoresApi extends BaseAPI implements StoresApiInterface {
    /**
     * 
     * @param {number} storeId 
     * @param {number} planId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoresApi
     */
    public storesArchivePlan(storeId: number, planId: number, options?: AxiosRequestConfig) {
        return StoresApiFp(this.configuration).storesArchivePlan(storeId, planId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateStoreRequest} createStoreRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoresApi
     */
    public storesCreate(createStoreRequest: CreateStoreRequest, options?: AxiosRequestConfig) {
        return StoresApiFp(this.configuration).storesCreate(createStoreRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {CreateLocationRequest} createLocationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoresApi
     */
    public storesCreateLocation(id: number, createLocationRequest: CreateLocationRequest, options?: AxiosRequestConfig) {
        return StoresApiFp(this.configuration).storesCreateLocation(id, createLocationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} storeId 
     * @param {CreatePlanRequest} createPlanRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoresApi
     */
    public storesCreatePlan(storeId: number, createPlanRequest: CreatePlanRequest, options?: AxiosRequestConfig) {
        return StoresApiFp(this.configuration).storesCreatePlan(storeId, createPlanRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} storeId 
     * @param {number} locationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoresApi
     */
    public storesDeleteLocation(storeId: number, locationId: number, options?: AxiosRequestConfig) {
        return StoresApiFp(this.configuration).storesDeleteLocation(storeId, locationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoresApi
     */
    public storesGet(id: number, options?: AxiosRequestConfig) {
        return StoresApiFp(this.configuration).storesGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} storeId 
     * @param {number} planId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoresApi
     */
    public storesGetPlan(storeId: number, planId: number, options?: AxiosRequestConfig) {
        return StoresApiFp(this.configuration).storesGetPlan(storeId, planId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} storeId 
     * @param {number} planId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoresApi
     */
    public storesGetPlanAddons(storeId: number, planId: number, options?: AxiosRequestConfig) {
        return StoresApiFp(this.configuration).storesGetPlanAddons(storeId, planId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} storeId 
     * @param {number} planId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoresApi
     */
    public storesGetRentalCounts(storeId: number, planId: number, options?: AxiosRequestConfig) {
        return StoresApiFp(this.configuration).storesGetRentalCounts(storeId, planId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoresApi
     */
    public storesList(options?: AxiosRequestConfig) {
        return StoresApiFp(this.configuration).storesList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoresApi
     */
    public storesListLocations(id: number, options?: AxiosRequestConfig) {
        return StoresApiFp(this.configuration).storesListLocations(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoresApi
     */
    public storesListPlans(id: number, options?: AxiosRequestConfig) {
        return StoresApiFp(this.configuration).storesListPlans(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoresApi
     */
    public storesListProrations(id: number, options?: AxiosRequestConfig) {
        return StoresApiFp(this.configuration).storesListProrations(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} storeId 
     * @param {number} planId 
     * @param {StorePlanAddonChangeRequestSet} storePlanAddonChangeRequestSet 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoresApi
     */
    public storesOverwritePlanAddons(storeId: number, planId: number, storePlanAddonChangeRequestSet: StorePlanAddonChangeRequestSet, options?: AxiosRequestConfig) {
        return StoresApiFp(this.configuration).storesOverwritePlanAddons(storeId, planId, storePlanAddonChangeRequestSet, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {NewProrationSet} newProrationSet 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoresApi
     */
    public storesOverwriteProrations(id: number, newProrationSet: NewProrationSet, options?: AxiosRequestConfig) {
        return StoresApiFp(this.configuration).storesOverwriteProrations(id, newProrationSet, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} storeId 
     * @param {number} planId 
     * @param {RentalCountSet} rentalCountSet 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoresApi
     */
    public storesOverwriteRentalCounts(storeId: number, planId: number, rentalCountSet: RentalCountSet, options?: AxiosRequestConfig) {
        return StoresApiFp(this.configuration).storesOverwriteRentalCounts(storeId, planId, rentalCountSet, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {PatchRequest} patchRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoresApi
     */
    public storesUpdate(id: number, patchRequest: PatchRequest, options?: AxiosRequestConfig) {
        return StoresApiFp(this.configuration).storesUpdate(id, patchRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} storeId 
     * @param {number} planId 
     * @param {PatchRequest} patchRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoresApi
     */
    public storesUpdatePlan(storeId: number, planId: number, patchRequest: PatchRequest, options?: AxiosRequestConfig) {
        return StoresApiFp(this.configuration).storesUpdatePlan(storeId, planId, patchRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {Array<Location>} location 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoresApi
     */
    public storesUpdateRouteStops(id: number, location: Array<Location>, options?: AxiosRequestConfig) {
        return StoresApiFp(this.configuration).storesUpdateRouteStops(id, location, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {UserCreationRequest} userCreationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersCreate: async (userCreationRequest: UserCreationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userCreationRequest' is not null or undefined
            assertParamExists('usersCreate', 'userCreationRequest', userCreationRequest)
            const localVarPath = `/api/v2/data/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userCreationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersDelete', 'id', id)
            const localVarPath = `/api/v2/data/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersGet', 'id', id)
            const localVarPath = `/api/v2/data/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/data/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersResendPasswordEmail: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersResendPasswordEmail', 'id', id)
            const localVarPath = `/api/v2/data/users/{id}/verify`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UserClaimsRequest} userClaimsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersSetClaims: async (id: string, userClaimsRequest: UserClaimsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersSetClaims', 'id', id)
            // verify required parameter 'userClaimsRequest' is not null or undefined
            assertParamExists('usersSetClaims', 'userClaimsRequest', userClaimsRequest)
            const localVarPath = `/api/v2/data/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userClaimsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {UserCreationRequest} userCreationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersCreate(userCreationRequest: UserCreationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersCreate(userCreationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserSet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersResendPasswordEmail(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersResendPasswordEmail(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UserClaimsRequest} userClaimsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersSetClaims(id: string, userClaimsRequest: UserClaimsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersSetClaims(id, userClaimsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * 
         * @param {UserCreationRequest} userCreationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersCreate(userCreationRequest: UserCreationRequest, options?: any): AxiosPromise<User> {
            return localVarFp.usersCreate(userCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.usersDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersGet(id: string, options?: any): AxiosPromise<User> {
            return localVarFp.usersGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersList(options?: any): AxiosPromise<UserSet> {
            return localVarFp.usersList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersResendPasswordEmail(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.usersResendPasswordEmail(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UserClaimsRequest} userClaimsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersSetClaims(id: string, userClaimsRequest: UserClaimsRequest, options?: any): AxiosPromise<User> {
            return localVarFp.usersSetClaims(id, userClaimsRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - interface
 * @export
 * @interface UsersApi
 */
export interface UsersApiInterface {
    /**
     * 
     * @param {UserCreationRequest} userCreationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    usersCreate(userCreationRequest: UserCreationRequest, options?: AxiosRequestConfig): AxiosPromise<User>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    usersDelete(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    usersGet(id: string, options?: AxiosRequestConfig): AxiosPromise<User>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    usersList(options?: AxiosRequestConfig): AxiosPromise<UserSet>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    usersResendPasswordEmail(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} id 
     * @param {UserClaimsRequest} userClaimsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    usersSetClaims(id: string, userClaimsRequest: UserClaimsRequest, options?: AxiosRequestConfig): AxiosPromise<User>;

}

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI implements UsersApiInterface {
    /**
     * 
     * @param {UserCreationRequest} userCreationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersCreate(userCreationRequest: UserCreationRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersCreate(userCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersDelete(id: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersGet(id: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersList(options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersResendPasswordEmail(id: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersResendPasswordEmail(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UserClaimsRequest} userClaimsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersSetClaims(id: string, userClaimsRequest: UserClaimsRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersSetClaims(id, userClaimsRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


